import { QuizResult } from "../models/QuizResult";

function paramsToUrl(params: QuizResult) {
	const {
		Realistyczny,
		Artystyczny,
		Badawczy,
		Konwencjonalny,
		Przedsiębiorczy,
		Społeczny,
	} = params;
	const searchParams = new URLSearchParams();

	const re = Realistyczny;
	const ar = Artystyczny;
	const ba = Badawczy;
	const ko = Konwencjonalny;
	const pr = Przedsiębiorczy;
	const sp = Społeczny;

	const codedParams = { re, ar, ba, ko, pr, sp };

	Object.keys(codedParams).forEach((key) => searchParams.append(key, codedParams[key]));

	return searchParams.toString();
}

export { paramsToUrl };
