import React, { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
	EmailShareButton,
	FacebookShareButton,
	TelegramShareButton,
	TwitterShareButton,
	WhatsappShareButton,
} from "react-share";
import { makeStyles } from "@material-ui/core";
import { FacebookIcon } from "../../icons/FacebookIcon";
import { TelegramIcon } from "../../icons/TelegramIcon";
import { TwitterIcon } from "../../icons/TwitterIcon";
import { WhatsAppIcon } from "../../icons/WhatsAppIcon";
import { MailIcon } from "../../icons/MailIcon";
import { LinkIcon } from "../../icons/LinkIcon";
import { Colors, theme } from "../../theme";
import { Typography } from "../../atoms/Typography";
import { Box } from "../../atoms/Box";
import { useMobile } from "../../../hooks/useMobile";

const useStyles = makeStyles({
	root: {
		[theme.breakpoints.up("md")]: {
			display: "flex",
		},
	},
	section: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		"& > button": {
			padding: 0,
			marginRight: "1rem",
			"&:last-child": {
				marginRight: 0,
			},
		},
	},
	copyUrlButton: {
		background: "transparent",
		border: "none",
		cursor: "pointer",
	},
	text: {
		fontWeight: 400,
		margin: "1.5rem",
	},
});

const SHARE_TITLE =
	"Jaki jest Twój typ zawodowy? \nJakie rodzaje stanowisk mają szansę najbardziej Ci odpowiadać? Sprawdź!";

const ShareQuizResult = () => {
	const styles = useStyles();
	const isServerSide = typeof window === "undefined";
	const url = !isServerSide ? window.location.href : "";
	const { isMobile } = useMobile();
	const [isCopied, setIsCopied] = useState(false);

	const handleUrlCopy = () => {
		setIsCopied(true);
		setTimeout(() => {
			setIsCopied(false);
		}, 1000);
	};

	return (
		<Box className={styles.root}>
			<Typography
				variant={"body2"}
				className={styles.text}
				align={isMobile ? "center" : "inherit"}
			>
				Ciekawi Cię, czy osoby, które znasz, mogłyby pracować z Tobą w tym samym zawodzie?
				Podeślij im ten test i porównajcie wyniki :)
			</Typography>
			<section className={styles.section}>
				<FacebookShareButton url={url} quote={SHARE_TITLE}>
					<FacebookIcon color={Colors.LightText} />
				</FacebookShareButton>
				<TelegramShareButton url={url} title={SHARE_TITLE}>
					<TelegramIcon color={Colors.LightText} />
				</TelegramShareButton>
				<TwitterShareButton url={url} title={SHARE_TITLE}>
					<TwitterIcon color={Colors.LightText} />
				</TwitterShareButton>
				<WhatsappShareButton url={url} title={SHARE_TITLE}>
					<WhatsAppIcon color={Colors.LightText} />
				</WhatsappShareButton>
				<EmailShareButton url={url} title={SHARE_TITLE}>
					<MailIcon color={Colors.LightText} />
				</EmailShareButton>
				<CopyToClipboard text={url} onCopy={handleUrlCopy}>
					<button className={styles.copyUrlButton}>
						<LinkIcon color={Colors.LightText} />
					</button>
				</CopyToClipboard>
			</section>
		</Box>
	);
};

export { ShareQuizResult };
