import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { SvgIcon } from "../../atoms/SvgIcon";
import { Colors, TColors } from "../../theme";

const useStyles = makeStyles({
	root: {
		width: "1rem",
		height: "100%",
		transform: ({ direction }: { direction: Direction }) =>
			direction === "right" ? "rotate(-180deg)" : "",
	},
});

type Direction = "left" | "right";

interface BackIconProps {
	direction: Direction;
	color?: TColors;
}

const BackIcon = (props: BackIconProps) => {
	const { direction, color } = props;

	const styles = useStyles({ direction });
	return (
		<SvgIcon className={styles.root} viewBox={"0 0 30 30"}>
			<path
				stroke={color || Colors.LightGrey}
				strokeWidth='2'
				strokeLinecap='round'
				strokeLinejoin='round'
				d='m10.464 14.964 7.072 7.072M17.535 7.859l-7.07 7.07'
			/>
		</SvgIcon>
	);
};

export { BackIcon };
