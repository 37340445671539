import { useEffect } from "react";
import { navigate } from "gatsby";
import { paramsToUrl } from "../utils/paramsToUrl";

const useSaveResultToUrl = (resultFromUrl, resultFromState) => {
	useEffect(() => {
		if (!resultFromUrl) {
			const params = paramsToUrl(resultFromState);

			navigate(`/result/?${params}`);
		}
	}, []);
};

export { useSaveResultToUrl };
