import { QuizResultState } from "../context/QuizResult/initialState";
import { PersonalityTypes } from "./PersonalityType";
import QuestionPages from "../content/QuestionPages.json";

const { pages } = QuestionPages;

const allQuestions = pages.map((page) => page.questions).flat();

const personalityTypePoints = {
	[PersonalityTypes.REALISTYCZNY]: 0,
	[PersonalityTypes.BADAWCZY]: 0,
	[PersonalityTypes.PRZEDSIEBIORCZY]: 0,
	[PersonalityTypes.ARTYSTYCZNY]: 0,
	[PersonalityTypes.SPOLECZNY]: 0,
	[PersonalityTypes.KONWENCJONALNY]: 0,
};

type Test = typeof personalityTypePoints;

function getPersonalityTypePoints(state: QuizResultState) {
	const stateCopy = { ...state };
	const stateKeys = Object.keys(stateCopy).filter((key) => !Number.isNaN(Number(key)));

	return stateKeys.reduce<Test>((points, key) => {
		const questionIndex = Number(key) - 1;
		const { personalityType } = allQuestions[questionIndex];
		const pointToBeAdded = stateCopy[key];

		if (personalityType) {
			return { ...points, [personalityType]: points[personalityType] + pointToBeAdded };
		}

		return { ...points };
	}, personalityTypePoints);
}

export { getPersonalityTypePoints };
