import React, { ReactElement } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { SvgIcon } from "../../atoms/SvgIcon";
import { theme } from "../../theme";

const useStyles = makeStyles({
	root: {
		[theme.breakpoints.down("sm")]: {
			width: "7.5rem",
		},
		[theme.breakpoints.up("sm")]: {
			width: "10.625rem",
		},
		height: "100%",
	},
});

interface PersonalityTypeIconProps {
	children: ReactElement;
	viewBox?: string;
}

const PersonalityTypeIcon = (props: PersonalityTypeIconProps) => {
	const { children, viewBox } = props;
	const styles = useStyles();

	return (
		<SvgIcon viewBox={viewBox || "0 0 120 120"} className={styles.root}>
			{children}
		</SvgIcon>
	);
};

export { PersonalityTypeIcon };
