import React from "react";
import { Colors, TColors } from "../../theme";
import { SocialIcon } from "../SocialIcon";

interface TwitterIconProps {
	color?: TColors;
}

const TwitterIcon = (props: TwitterIconProps) => {
	const { color } = props;
	return (
		<SocialIcon color={color || Colors.Text}>
			<g transform='translate(-606.5 -6)' fillRule='evenodd'>
				<g transform='translate(502.5 6)'>
					<path d='M109 0h22a5 5 0 0 1 5 5v22a5 5 0 0 1-5 5h-22a5 5 0 0 1-5-5V5a5 5 0 0 1 5-5zm7.22 26.534c8.496 0 13.142-7.04 13.142-13.142 0-.2 0-.402-.01-.594a9.462 9.462 0 0 0 2.309-2.394 9.374 9.374 0 0 1-2.654.728 4.616 4.616 0 0 0 2.031-2.558c-.89.527-1.877.91-2.931 1.12a4.597 4.597 0 0 0-3.372-1.455 4.62 4.62 0 0 0-4.617 4.617c0 .364.039.718.125 1.053a13.103 13.103 0 0 1-9.521-4.827 4.633 4.633 0 0 0-.623 2.318c0 1.6.814 3.017 2.06 3.84a4.537 4.537 0 0 1-2.088-.574v.058c0 2.241 1.59 4.1 3.706 4.53a4.604 4.604 0 0 1-2.088.077A4.612 4.612 0 0 0 116 22.54a9.284 9.284 0 0 1-6.838 1.906 12.868 12.868 0 0 0 7.059 2.088z' />
				</g>
			</g>
		</SocialIcon>
	);
};

export { TwitterIcon };
