import { getCombinedPersonalityType } from "./getCombinedPersonalityType";
import { getCombinedPersonalityTypeShorthand } from "./getCombinedPersonalityTypeShorthand";

export function getDetailedCombinedPersonalityType(
	firstType: string,
	secondType: string,
	combinedPersonalityType: ReturnType<typeof getCombinedPersonalityType>
) {
	const combinedPersonalityTypeShorthand = getCombinedPersonalityTypeShorthand(
		firstType,
		secondType
	);
	return combinedPersonalityType.detailedType[combinedPersonalityTypeShorthand];
}
