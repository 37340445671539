import React from "react";
import { PersonalityTypeIcon } from "../PersonalityTypeIcon";

const BSIcon = () => {
	return (
		<PersonalityTypeIcon>
			<svg width='120' height='120' xmlns='http://www.w3.org/2000/svg'>
				<g fill='none' fillRule='evenodd'>
					<path
						d='M60 0c33.132 0 60 26.868 60 60 0 33.131-26.868 60-60 60C26.87 120 0 93.13 0 60 0 26.868 26.869 0 60 0'
						fill='#E2DFCE'
					/>
					<path
						d='m51.462 18.667 19.525.63 45.557 20.604A59.884 59.884 0 0 1 120 60c0 33.13-26.869 60-60 60-.504 0-1.007-.007-1.508-.019l-7.03-101.314z'
						fill='#D2CDAF'
					/>
					<path
						d='M43.683 45.556c.006-.02.008-.044.018-.062h-.025c0 .018.01.055.007.062'
						fill='#733C2A'
					/>
					<path
						d='M15.436 84.69c-1.884 3.712-2.835 8.351-2.292 12.778 10.997 13.731 27.9 22.531 46.856 22.531 18.957 0 35.86-8.799 46.856-22.531.544-4.427-.407-9.066-2.292-12.777C96.96 69.7 76.946 72.178 74.356 68.328c-2.01-2.988-.812-12.177-.947-14.544-9.366.899-17.36.702-26.723-.193-.135 2.364.968 11.75-1.042 14.737-2.59 3.85-22.604 1.373-30.208 16.363'
						fill='#C48E5D'
					/>
					<path
						d='M15.436 84.69c-1.884 3.712-2.835 8.351-2.292 12.778 10.997 13.731 27.9 22.531 46.856 22.531 18.957 0 35.86-8.799 46.856-22.531.544-4.427-.407-9.066-2.292-12.777C98.78 73.292 85.825 71.994 78.81 70.333 76.99 76.355 68.98 79.09 60 79.09s-15.965-2.89-17.626-9.059c-6.636 1.845-20.81 2.583-26.938 14.659'
						fill='#DEDCDB'
					/>
					<path
						d='M79.812 36.386c-.57-.705-1.398-.889-2.24-.775.008-.508.024-1.02.024-1.527 0-4.57-.016-10.174-1.622-13.374-1.173-2.34-2.954-3.871-5.063-5.002-8.073-4.338-13.75-4.338-21.825 0-2.109 1.13-3.886 2.662-5.06 5.002-1.607 3.2-1.622 8.804-1.622 13.374 0 .506.015 1.019.025 1.527-.846-.114-1.671.07-2.244.775-1.33 1.647-.745 5.2.552 7.724.475.928 1.045 1.716 1.885 2.317.31.221.634.393.944.478.573 2.79 1.385 5.438 2.531 7.773 1.49 3.026 3.524 5.541 6.265 7.211 4.3 2.619 10.973 2.619 15.275 0 2.741-1.67 4.776-4.185 6.262-7.21 1.15-2.336 1.96-4.984 2.535-7.774.31-.089.631-.257.941-.478.84-.601 1.41-1.389 1.885-2.317 1.296-2.525 1.882-6.077.552-7.724'
						fill='#E0A871'
					/>
					<path
						d='M45.874 67.2 60.02 79.062c-1.028 7.036-4.115 9.726-9.258 8.071-5.144-1.655-10.068-6.922-14.77-15.802l9.88-4.133z'
						fill='#FAFAFA'
					/>
					<path
						d='M74.123 67.2 59.979 79.062c1.03 7.036 4.115 9.726 9.259 8.071 5.143-1.655 10.066-6.922 14.77-15.802L74.122 67.2z'
						fill='#FAFAFA'
					/>
					<path
						d='M84.973 71.672c-12.676 21.7-21 32.55-24.973 32.55-3.973 0-12.295-10.848-24.967-32.546-6.877 1.6-15.254 4.456-19.597 13.015-1.737 3.423-2.833 7.225-3.524 11.19C22.853 110.52 40.322 119.999 60 119.999c19.679 0 37.147-9.479 48.09-24.118-.691-3.964-1.788-7.767-3.526-11.19-4.34-8.552-12.717-11.42-19.59-13.02z'
						fill='#264C73'
					/>
					<path
						d='m27.722 106.083-.413 4.228c.206.135.41.267.618.398l-.205-4.626zM92.28 106.083l.413 4.228c-.206.135-.412.267-.619.398l.206-4.626z'
						fill='#1B3254'
					/>
					<path
						d='M60.255 23.528c-2.175.745-6.059-.567-7.883-.35-5.3.63-6.5 6.627-7.192 12.304l-.074.617c-.36 3.08-.607 6.003-1.324 7.847.058-.152-.686-2.458-1.013-3.866-.255-1.093-.481-2.117-.458-2.376.061-.594.044-1.428-.117-2.099-2.857-11.929.098-22.678 16.703-23.1a27.96 27.96 0 0 1 1.365-.01l.448-.004c.301 0 .607.004.916.014 16.605.422 19.557 11.171 16.703 23.1-.162.671-.177 1.505-.119 2.1.027.258-.203 1.282-.457 2.375-.328 1.408-1.073 3.714-1.012 3.866-2.08-5.347-.205-19.77-8.59-20.768-1.824-.217-5.705 1.095-7.883.35l-.006-.004-.007.004zM75.046 48.197c0 1.467.03 1.457-.186 2.213-.424 1.518-1.203 2.5-2.25 3.325-.417.332-1.524-.864-1.887-.044-.233.517-.433 1.277-.66 1.788-.074-.143-.022-.801-.143-.84-.135.306-.573 1.102-.71 1.412-.232.525-.642 1.1-1.173 1.655-1.357 1.429-2.311 2.551-4.372 2.7-1.02.075-1.736-.725-2.251-1.511-.402-.615-.685-1.227-.891-1.41-.705-.637-1.86.258-2.554 1.081-.67.79-1.286 1.446-2.045 1.716-.976.345-2.146-.002-3.034-.637-.69-.494-1.208-1.163-1.328-1.82-.226-1.217-1.303-2.52-1.187-2.777-1.661 1.311-2.83-.345-3.534-2.193-.903-2.381-1.211-6.564-1.991-7.201-1.37-1.119.373.637 0 2.225-.267 1.14-.184 2.09.034 3.037.217.946.566 1.88.828 2.977.21.87.198 1.702.22 2.402.035 1.074.422 2.609 1.117 3.982.891 1.765 1.626 2.829 3.428 2.766.425-.017 1.2 1.89 1.656 2.353.812.836 1.215.624 1.662.362 1.541-.904 1.688 1.003 2.65 1.111 1.86.21 2.02-.755 3.157-.675 1.066.075 1.164 1.101 2.964.924 1.105-.107 1.48-1.18 1.98-1.016.117.04.994.34 1.446.111.76-.382 1.218-1.4 1.401-1.433.891-.158 1.756-.1 2.278-.287 1.567-.56 1.579-1.56 2.295-3.17.914-2.052-.084-.982 1.23-2.438.613-.675.976-1.625 1.228-2.631.419-1.67.534-3.51.992-4.535.278-.627 1.08-2.782.939-3.755-.203-1.428.38-1.924.38-2.31-.338.45-1.689 1.075-1.689 2.543z'
						fill='#9A5E50'
					/>
					<path
						d='M58.282 44.964a3.43 3.43 0 0 0-1.515.316c-1.216.531-2.11 1.608-3.263 2.324-.661.408-1.473.51-2.311.535-.825.021-1.365.777-1.49 1.764-.02.146.37-.149.726-.358.577-.338.848.03 1.444.2 1.014.288 2.2.362 2.846.362 1.282 0 2.113-.176 3.269-.532.539-.17 1.41-.804 2.025-1.554.095-.114.179-.225.249-.334.073.11.156.22.25.334.616.75 1.487 1.384 2.03 1.554 1.15.356 1.983.532 3.264.532.647 0 1.83-.074 2.844-.361.598-.17.87-.539 1.447-.201.356.21.745.504.727.358-.13-.987-.666-1.743-1.49-1.764-.837-.024-1.65-.127-2.311-.535-1.152-.716-2.05-1.793-3.267-2.324a3.4 3.4 0 0 0-1.511-.316c-.847-.004-1.564.237-1.983.662-.417-.425-1.134-.666-1.98-.662M72.544 17.53c3.535.379 1.217 10.223-6.776 10.84 5.794-2.678 5.023-12.244 1.29-10.69.477-.081 5.486-.15 5.486-.15'
						fill='#9A5E50'
					/>
				</g>
			</svg>
		</PersonalityTypeIcon>
	);
};

export { BSIcon };
