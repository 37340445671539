import React from "react";
import { PersonalityTypeIcon } from "../PersonalityTypeIcon";

const PAIcon = () => {
	return (
		<PersonalityTypeIcon>
			<svg
				width='120'
				height='120'
				xmlns='http://www.w3.org/2000/svg'
				xmlnsXlink='http://www.w3.org/1999/xlink'
			>
				<defs>
					<path id='a' d='M0 0h120v120H0z' />
				</defs>
				<g fill='none' fillRule='evenodd'>
					<g>
						<mask id='b' fill='#fff'>
							<use xlinkHref='#a' />
						</mask>
						<path
							d='M60 0c33.132 0 60 26.869 60 60 0 33.132-26.868 60-60 60C26.87 120 0 93.132 0 60 0 26.87 26.869 0 60 0'
							fill='#E5D9BA'
							mask='url(#b)'
						/>
					</g>
					<path
						d='m51.462 21.01 19.525.631 46.462 21.011A59.966 59.966 0 0 1 120 60c0 33.132-26.868 60-60 60-.505 0-1.007-.006-1.508-.02l-7.03-98.97z'
						fill='#D6C596'
					/>
					<path
						d='M16.7 86.958c-1.856 3.527-2.78 7.947-2.201 12.146C25.503 111.896 41.807 120 60 120c18.192 0 34.494-8.103 45.498-20.895.58-4.198-.343-8.619-2.201-12.147-7.388-14.035-26.835-11.715-29.35-15.317-1.953-2.801-.789-11.403-.92-13.616-9.1.84-16.867.655-25.964-.182-.131 2.213.941 10.997-1.014 13.798-2.515 3.602-21.96 1.282-29.35 15.317'
						fill='#ECBC8F'
					/>
					<path
						d='M16.7 86.958c-1.856 3.527-2.78 7.947-2.201 12.146C25.503 111.896 41.807 120 60 120c18.192 0 34.494-8.103 45.498-20.895.58-4.198-.343-8.619-2.201-12.147-3.004-5.702-7.999-8.705-13.04-10.503-7.452 7.226-18.245 11.773-30.26 11.773-12.011 0-22.81-4.547-30.26-11.773-5.04 1.798-10.034 4.8-13.037 10.503'
						fill='#F1DBA4'
					/>
					<path
						d='M16.7 86.958c-1.722 3.27-2.795 6.871-3.464 10.625C24.234 111.25 41.096 120 60 120c18.903 0 35.763-8.75 46.762-22.415-.668-3.755-1.743-7.356-3.465-10.627-5.366-10.194-17.094-11.756-24.073-13.233-11.456 27.662-26.996 27.662-38.45 0-6.982 1.473-18.708 3.039-24.074 13.233'
						fill='#18332F'
					/>
					<path
						d='M52.612 68.757c-2.65-1.65-4.62-4.138-6.058-7.135-1.036-2.159-1.797-4.579-2.35-7.14-.327-.071-.678-.26-1.01-.494-.81-.578-1.366-1.336-1.824-2.226-1.254-2.423-1.822-5.829-.533-7.405.555-.68 1.358-.872 2.176-.76a89.59 89.59 0 0 1-.03-2.347c0-4.52.014-10.064 1.569-13.23 1.134-2.312 2.855-3.83 4.894-4.95 7.808-4.286 13.296-4.286 21.107 0 2.039 1.12 3.758 2.638 4.896 4.95 1.554 3.166 1.57 8.71 1.57 13.23 0 .779-.013 1.564-.033 2.348.818-.113 1.62.08 2.176.759 1.283 1.576.721 4.982-.531 7.405-.463.89-1.015 1.648-1.827 2.226-.331.234-.685.423-1.013.494-.548 2.561-1.31 4.981-2.35 7.14-1.439 2.997-3.403 5.486-6.056 7.135-4.158 2.586-10.613 2.586-14.773 0'
						fill='#FCCA9A'
					/>
					<path d='m44.252 54.487-.05-.009c.027 0 .04 0 .05.009' fill='#A17351' />
					<path fill='#070000' d='m44.256 54.49-.004-.003v-.009z' />
					<path
						d='M57.11 102.246s-2.77-35.895-17.565-28.21c-3.731 2.332-3.678 14.26-4.72 20.566-.657 3.974-.872 12.012-.774 19.509a59.692 59.692 0 0 0 24.185 5.862 67258.26 67258.26 0 0 1-1.125-17.727'
						fill='#297B8F'
					/>
					<path
						d='M46.982 60.017c-4.414 0-10.764 11.101-19.18 16.115.398 22.05 19.002 36.073 31.832 37.676 12.832 1.604 20.848-9.022 20.848-9.022s12.03-12.832 10.624-24.058c-2.563-5.59-10.56-8.486-13.63-10.02-3.069-1.53-2.051-7.832-4.657-5.43-3.41 4.61-1.06 29.223-1.56 31.691-.498 2.468-3.09 6.873-4.61 7.817-1.521.946-4.41 1.606-7.418.403-3.606-24.257-12.051-35.747-12.249-45.172'
						fill='#5A99AA'
					/>
					<path
						d='M75.74 21.319c-2.352-3.275-6.813-4.644-10.285-5.223-2.367-.392-9.128-.472-11.97.144-3.256.704-7.05 2.14-9.16 5.079-3.952 5.51-7.199 14.994-5.698 24.308.319-1.118 1.225-1.78 1.225-1.78s-.727 5.777 1.119 9.318c.241-1.007.829-2.588.829-2.588s.04 8.405 2.888 10.277c-.963-1.35-.354-2.673-.354-2.673s2.718 7.798 6.439 8.326c-3.15-1.839-3.683-7.484-3.683-7.484s1.549.846 1.8.097c-1.89.082-2.324-2.87-2.324-2.87s1.197.647 1.356.213c-1.075-.221-2.57-4.57-2.57-4.57s2.442 2.8 4.886 2.038c-4.584-3.82-4.146-9.656-2.928-12.516.46-1.08 1.367-2.742 2.798-4.354.322.852 1.365 4.22-1.773 5.438 4.777 1.322 7.148-2.931 7.148-2.931s-.076 5.864-3.284 7.135c5.308.598 7.454-6.643 7.454-6.643s1.26.739 1.004 2.878c3.515-.46 3.33-3.42 3.33-3.42s1.326 2.32-.706 4.688c4.005-.001 6.884-5.38 6.884-5.38s.76 1.329-1.75 3.836c2.737.527 4.215-.922 4.424-1.477.649 2.494 2.273 9.813-.54 12.755.678.416 1.896-.302 1.896-.302s-2.51 4.09-3.589 4.313c.159.432 2.892-1.7 2.892-1.7s-.345 3.84-4.672 4.603c.25.747 3.766-.358 3.766-.358s-.67 6.108-3.817 7.946c3.72-.528 6.671-9.318 6.671-9.318s-.056 3.536-1.017 4.888c2.846-1.872 3.837-13.435 3.837-13.435s.247.816.487 1.826c1.852-3.544 1.222-9.573 1.222-9.573s.522-.253.838.865c1.502-9.318-1.12-16.867-5.073-22.376'
						fill='#6E4019'
					/>
					<path
						d='m28.638 106.486-.43 4.4c.214.134.426.27.642.4l-.212-4.8zM91.362 106.486l.43 4.4c-.214.134-.427.27-.643.402l.213-4.802z'
						fill='#0F2320'
					/>
				</g>
			</svg>
		</PersonalityTypeIcon>
	);
};

export { PAIcon };
