import React from "react";
import { navigate } from "gatsby";
import { makeStyles } from "@material-ui/core";
import { Button } from "../Button";
import { BackIcon } from "../../icons/BackIcon";
import { Colors } from "../../theme";
import { Box } from "../Box";

const useStyles = makeStyles({
	allTypesButton: {
		letterSpacing: "2px",
	},
});

const navigateToAllPersonalityTypes = () => {
	navigate(`/types`);
};

const AllPersonalityTypesButton = () => {
	const styles = useStyles();

	return (
		<Button
			variant={"text"}
			onClick={navigateToAllPersonalityTypes}
			className={styles.allTypesButton}
		>
			Poznaj wszystkie typy{" "}
			<Box
				marginLeft={1.5}
				display={"flex"}
				alignItems={"center"}
				justifyContent={"center"}
			>
				<BackIcon direction={"right"} color={Colors.Green} />
			</Box>
		</Button>
	);
};

export { AllPersonalityTypesButton };
