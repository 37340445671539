import React from "react";
import { PersonalityTypeIcon } from "../PersonalityTypeIcon";

const PKIcon = () => {
	return (
		<PersonalityTypeIcon>
			<svg
				width='120'
				height='120'
				xmlns='http://www.w3.org/2000/svg'
				xmlnsXlink='http://www.w3.org/1999/xlink'
			>
				<defs>
					<path id='a' d='M0 0h120v120H0z' />
				</defs>
				<g fill='none' fillRule='evenodd'>
					<g>
						<mask id='b' fill='#fff'>
							<use xlinkHref='#a' />
						</mask>
						<path
							d='M60 0c33.132 0 60 26.869 60 60 0 33.132-26.868 60-60 60C26.87 120 0 93.132 0 60 0 26.87 26.869 0 60 0'
							fill='#B0C9DC'
							mask='url(#b)'
						/>
					</g>
					<path
						d='m51.461 20.624 19.526.631 46.33 20.952A59.955 59.955 0 0 1 120 60c0 33.132-26.868 60-60 60-.505 0-1.007-.006-1.508-.02l-7.03-99.356z'
						fill='#97B8CC'
					/>
					<path
						d='m69.188 60.46-5.35.339h-.001c-4.374 0-8.613-.06-13.023-.34l-.911 6.94c-.473 3.603-1.541 6.059-5.318 6.556-9.535 1.256-15.454 2.84-19.647 9.019-3.218 4.74-5.917 11.963-5.123 17.718.28 2.04.82 3.759 1.584 5.233C31.833 114.707 45.298 120 60 120c14.7 0 28.166-5.293 38.6-14.074.765-1.473 1.304-3.195 1.586-5.234.794-5.755-1.906-12.977-5.125-17.718-4.192-6.178-10.11-7.763-19.645-9.02-3.776-.496-4.85-2.952-5.322-6.556l-.906-6.938z'
						fill='#231F20'
					/>
					<path
						d='M74.927 51.932c-1.624 5.826-4.5 10.08-8.235 13.25-5.902 5.011-7.812 4.978-13.693-.043-3.724-3.178-6.588-7.443-8.203-13.28-.397.37-1.023.114-1.575-.31-.696-.537-1.168-1.237-1.562-2.067-1.076-2.254-1.56-5.42-.457-6.886.345-.463.812-.68 1.308-.725a179.51 179.51 0 0 1-.794-3.022C39.1 28.387 48.07 19.452 60.001 17.945c11.335 1.13 20.252 10.498 18.026 20.904-.418 1.941-.787 2.17.398 3.747 1.104 1.465.62 4.632-.456 6.886-.395.83-.868 1.53-1.563 2.066-.51.391-1.079.639-1.479.384'
						fill='#F6C99E'
					/>
					<path
						d='M100.322 98.079c-.133-8.254-3.775-15.054-5.072-17.088-1.61-2.513-3.574-3.574-5.861-3.978-3.965-.698-7.427-2.084-11.282-2.678-.092 12.33-3.506 30.588-5.425 44.317 10.654-2.293 20.257-7.416 27.985-14.544-.028-2.031-.125-4.043-.345-6.03'
						fill='#808184'
					/>
					<path
						d='M46.037 25.407c-.265-4.8 5.35-7.754 9.502-9.356 12.756-4.919 23.4 4.992 27.247 13.235 1.377 2.945.844 5.928-.198 8.762-1.623 4.398 2.926 10.273-3.807 16.327 0 0-4.371 5.816-9.835 4.03 2.295-1.363 3.248-4.716 2.75-5.857-2.153-4.941-8.11-7.512-10.08-11.747-1.327-2.857-3.027-5.386-4.716-8.096-.79-1.27-1.537-2.427-2.617-3.473-1.351-1.31-3.204-2.53-4.992-1.969-.598.186-1.377.722-2.89.31 0-.568-.097-1.318-.364-2.166'
						fill='#FFF'
					/>
					<path
						d='M46.715 21.576c-2.558.989-5.063 3.026-6.074 5.328-1.493 3.398-.036 7.774-1.086 11.86-.854 3.343.497 4.653.978 6.665-.015-.515.767-2.518 1.511-2.518 2.453 0 1.644-2.482 1.644-4.628 0-1.81.616-3.63 1.28-5.086.933-2.047 2.462-2.526 2.462-5.125 0-1.016 1.54-7.369-.715-6.496M44.739 50.289l-.604-.602a.333.333 0 0 0-.466 0l-.604.602a.339.339 0 0 0 0 .469l.604.6c.128.13.34.13.466 0l.604-.6a.333.333 0 0 0 0-.47'
						fill='#FFF'
					/>
					<path
						d='M19.679 98.079c.133-8.254 3.77-15.054 5.072-17.088 1.609-2.513 3.576-3.574 5.86-3.978 3.964-.698 7.427-2.084 11.281-2.678.094 12.33 3.505 30.588 5.426 44.317-10.654-2.293-20.257-7.416-27.985-14.544.027-2.031.125-4.043.346-6.03'
						fill='#808184'
					/>
					<path
						d='m86.172 114.002-.049-.475c-1.133-11.162-.934-11.429-1.369-.01l-.044 1.174c.491-.223.977-.453 1.462-.689M35.238 114.666l-.043-1.148c-.423-11.42-.23-11.153-1.336.009l-.046.468c.472.23.946.453 1.425.671'
						fill='#58595B'
					/>
				</g>
			</svg>
		</PersonalityTypeIcon>
	);
};

export { PKIcon };
