import { PersonalityType } from "./PersonalityType";
import { CombinedPersonalityTypes } from "./CombinedPersonalityTypes";

function getCombinedPersonalityType(
	firstType: PersonalityType,
	secondType: PersonalityType
) {
	if (firstType === secondType) {
		throw new Error(
			`Incorrect types: ${firstType}, ${secondType}. You need to pass two different types to generate combined personality type`
		);
	}

	const combinedTypesValues = Object.values(CombinedPersonalityTypes);
	return combinedTypesValues.find(({ types }) =>
		types.every((type) => type === firstType || type === secondType)
	);
}

export { getCombinedPersonalityType };
