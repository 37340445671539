import React from "react";
import { PersonalityTypeIcon } from "../PersonalityTypeIcon";

const PSIcon = () => {
	return (
		<PersonalityTypeIcon>
			<svg
				width='120'
				height='120'
				xmlns='http://www.w3.org/2000/svg'
				xmlnsXlink='http://www.w3.org/1999/xlink'
			>
				<defs>
					<path id='a' d='M0 0h120v120H0z' />
					<path id='c' d='M0 0h68.538v101.334H0z' />
				</defs>
				<g fill='none' fillRule='evenodd'>
					<g>
						<mask id='b' fill='#fff'>
							<use xlinkHref='#a' />
						</mask>
						<path
							d='M60 0c33.131 0 60 26.868 60 60 0 33.13-26.869 60-60 60-33.132 0-60-26.87-60-60C0 26.868 26.868 0 60 0'
							fill='#CDD6CA'
							mask='url(#b)'
						/>
					</g>
					<g transform='translate(51.461 18.667)'>
						<mask id='d' fill='#fff'>
							<use xlinkHref='#c' />
						</mask>
						<path
							d='m0 0 19.525.63 45.558 20.604a59.875 59.875 0 0 1 3.455 20.099c0 33.13-26.868 60-60 60a61.16 61.16 0 0 1-1.507-.019L0 0z'
							fill='#B5C3B0'
							mask='url(#d)'
						/>
					</g>
					<path
						d='M78.559 33.222c0 11.432-8.236 20.697-18.396 20.697s-18.395-9.265-18.395-20.697c0-11.43 8.235-20.695 18.395-20.695S78.56 21.792 78.56 33.222'
						fill='#A87836'
					/>
					<path
						d='M48.819 66.717c-2.992 3.946-3.148 6.48-3.148 6.48s-4.825 18.419-.634 22.768c4.189 4.35 28.699 5.296 31.941.552 3.24-4.741-2.452-22.61-2.452-22.61s.963-2.36-3.392-7.187c-4.355-4.831-19.324-3.951-22.315-.003'
						fill='#593B56'
					/>
					<path
						d='m50.79 60.455 5.348.343h.003c4.374 0 8.612-.06 13.023-.343l.907 6.942c.472 3.602 1.542 6.147 5.32 6.644 9.536 1.257 15.01 2.753 19.202 8.932 3.219 4.742 5.919 11.962 5.125 17.72-.32 2.31-.933 4.213-1.813 5.81C87.57 114.938 74.375 120 60 120c-14.416 0-27.644-5.09-37.991-13.565-.86-1.584-1.46-3.465-1.774-5.743-.793-5.757 1.907-12.977 5.125-17.72 4.192-6.178 9.667-7.674 19.201-8.93 3.776-.498 4.85-3.043 5.32-6.645l.908-6.942z'
						fill='#F1C89B'
					/>
					<path
						d='M45.05 51.931c1.623 5.828 4.501 10.079 8.234 13.25 5.9 5.011 7.81 4.978 13.691-.043 3.727-3.181 6.59-7.445 8.205-13.28.398.367 1.02.114 1.572-.314.696-.532 1.17-1.237 1.566-2.065 1.076-2.253 1.56-5.419.457-6.884a1.781 1.781 0 0 0-1.312-.725c.258-.957.656-1.926.797-3.024 1.392-10.858-6.354-19.394-18.288-20.905C48.64 19.075 39.725 28.439 41.95 38.846c.414 1.941.788 2.171-.398 3.75-1.104 1.464-.62 4.63.457 6.883.394.828.866 1.533 1.562 2.065.51.394 1.08.642 1.478.387'
						fill='#FDDFBF'
					/>
					<path
						d='M95.038 82.972c-3.902-5.75-9.299-7.52-17.713-8.75l-.49-.117c-1.159-.523-3.264-5.203-5.7-7.386 1.847 2.256 3.76 16.846-10.078 26.292-.472-15.534-17.048-14.755-12.238-26.294-.944.812-4.542 6.865-5.701 7.388l-.497.117c-8.407 1.23-13.804 3-17.706 8.75-4.54 6.69-5.268 13.945-5.303 21.394C30.274 114.077 44.446 120 60 120c15.614 0 29.835-5.97 40.51-15.749-.063-7.467-1.054-14.768-5.472-21.279'
						fill='#234866'
					/>
					<path
						d='M61.403 115.275c-.018-8.736-.046-19.317 1.94-23.96a45.055 45.055 0 0 1-2.286 1.696c-1.337 5.277-1.245 11.266-1.223 22.266.006 1.672.01 3.259.002 4.72L60 120c.469 0 .936-.007 1.403-.018.006-1.439.006-3.01 0-4.707'
						fill='#193242'
					/>
					<path
						d='M78.683 35.445c.064-9.516-4.735-15.707-12.704-17.752a23.609 23.609 0 0 0-11.87.043c-7.853 2.078-12.84 8.21-12.84 17.709 0 3.095.53 6.163 1.515 9.032a37.473 37.473 0 0 1-.092-.898c2.602-7.15 9.357-12.25 17.283-12.25.885 0 1.75.073 2.6.196l1.176-10.813.086 11.044c6.194 1.345 11.239 5.853 13.412 11.796-.028.29-.058.581-.092.87a28.463 28.463 0 0 0 1.526-8.977'
						fill='#A87836'
					/>
					<path
						d='M58.017 102.321a3.674 3.674 0 1 1-3.672-3.674 3.673 3.673 0 0 1 3.672 3.674'
						fill='#193242'
					/>
					<path
						d='M56.055 102.321c0 .617-.42 1.113-.941 1.113H47.16c-.52 0-.94-.496-.94-1.113 0-.619.42-1.114.94-1.114h7.954c.52 0 .941.495.941 1.114'
						fill='#193242'
					/>
					<path
						d='M56.738 102.321a2.393 2.393 0 0 1-2.393 2.394 2.393 2.393 0 1 1 0-4.787c1.32 0 2.393 1.07 2.393 2.393'
						fill='#B4C0C8'
					/>
					<path
						d='M58.017 114.44a3.674 3.674 0 1 1-7.347 0 3.673 3.673 0 1 1 7.347 0'
						fill='#193242'
					/>
					<path
						d='M56.055 114.44c0 .617-.42 1.116-.941 1.116H47.16c-.52 0-.94-.5-.94-1.116 0-.616.42-1.114.94-1.114h7.954c.52 0 .941.498.941 1.114'
						fill='#193242'
					/>
					<path
						d='M56.738 114.44a2.393 2.393 0 0 1-2.393 2.394 2.394 2.394 0 1 1 0-4.788c1.32 0 2.393 1.07 2.393 2.394'
						fill='#B4C0C8'
					/>
					<path
						d='m33.827 114.001.049-.476c1.133-11.162.936-11.428 1.371-.01l.045 1.176a63.4 63.4 0 0 1-1.465-.69M84.762 114.666l.043-1.151c.423-11.418.23-11.152 1.337.01l.046.47c-.472.23-.947.453-1.426.67'
						fill='#193242'
					/>
				</g>
			</svg>
		</PersonalityTypeIcon>
	);
};

export { PSIcon };
