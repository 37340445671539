import React from "react";
import { Colors, TColors } from "../../theme";
import { SocialIcon } from "../SocialIcon";

interface TelegramIconProps {
	color?: TColors;
}

const TelegramIcon = (props: TelegramIconProps) => {
	const { color } = props;
	return (
		<SocialIcon color={color || Colors.Text}>
			<g transform='translate(-554.5 -6)' fillRule='evenodd'>
				<g transform='translate(502.5 6)'>
					<path d='M79 0a5 5 0 0 1 5 5v22a5 5 0 0 1-5 5H57a5 5 0 0 1-5-5V5a5 5 0 0 1 5-5h22zm-1.789 7.625c-.233-.019-.52.03-.867.164l-17.406 6.758c-.561.218-1.746.959-.092 1.482l4.292 1.354 1.815 5.565c.21.641.915.535 1.294.17l2.42-2.337 4.403 3.214c.85.62 1.555.504 1.758-.435l3.117-14.438c.169-.779-.036-1.443-.734-1.5zm-3.089 3.002c.317-.056.526.115.068.526l-8.292 7.443c-.071.063-.074.105-.08.17l-.323 3.312-1.589-4.875c-.061-.189.004-.184.063-.219l9.82-6.224a.994.994 0 0 1 .333-.133z' />
				</g>
			</g>
		</SocialIcon>
	);
};

export { TelegramIcon };
