import React from "react";
import { PersonalityTypeIcon } from "../PersonalityTypeIcon";

const BKIcon = () => {
	return (
		<PersonalityTypeIcon>
			<svg width='120' height='120' xmlns='http://www.w3.org/2000/svg'>
				<g fill='none' fillRule='evenodd'>
					<path
						d='M60 0c33.131 0 60 26.868 60 60 0 33.131-26.869 60-60 60S0 93.13 0 60C0 26.868 26.869 0 60 0'
						fill='#85878A'
					/>
					<path
						d='m51.462 18.667 19.525.631 45.558 20.603A59.895 59.895 0 0 1 120 60c0 33.131-26.869 60-60 60a59.64 59.64 0 0 1-1.509-.02L51.462 18.667z'
						fill='#6A6A6C'
					/>
					<path
						d='M32.912 23.426h1.061c.272-.785.566-1.549.877-2.284 2.41-5.72 6.383-9.794 11.948-12.5 8.812-4.28 17.59-4.283 26.404 0 5.567 2.706 9.536 6.78 11.947 12.5.312.735.605 1.499.878 2.284h1.063c4.913 0 8.934 5.973 8.934 13.269v26.936c7.648 7.066 12.419 16.731 15.358 27.357C100.874 108.373 81.792 120 60 120c-21.792 0-40.874-11.627-51.384-29.012 2.942-10.626 7.711-20.291 15.358-27.357V36.695c0-7.296 4.023-13.269 8.938-13.269'
						fill='#EBECED'
					/>
					<path
						d='M84.328 41.016c0 11.779-11.002 21.328-24.568 21.328-13.565 0-24.567-9.55-24.567-21.328 0-21.859 49.135-21.859 49.135 0'
						fill='#473F41'
					/>
					<path
						d='M80.624 41.286c0 10.342-8.051 18.947-18.667 20.72 8.095-3.713 13.713-11.9 13.713-21.408 0-6.447-3.351-11.169-8.26-14.17 7.436 2.21 13.214 7.159 13.214 14.858'
						fill='#FFF'
					/>
					<path
						d='M45.397 76.272h29.206c3.346 0 6.09 2.739 6.09 6.085v29.213c0 3.345-2.744 6.087-6.09 6.087H45.397c-3.348 0-6.09-2.742-6.09-6.087V82.357c0-3.346 2.742-6.085 6.09-6.085'
						fill='#B1B3B5'
					/>
					<path
						d='M47.522 35.155c-.815 1.829-2.05 3.122-3.714 3.884a.11.11 0 0 1-.025.013c.01.003.018.008.028.01 1.66.764 2.896 2.059 3.711 3.887.023.048.052.117.052.117a.713.713 0 0 0 .053-.117c.82-1.833 2.063-3.127 3.731-3.893l.012-.006c-.004-.001-.01-.001-.012-.005-1.668-.76-2.913-2.057-3.73-3.891a.66.66 0 0 0-.054-.114s-.029.068-.052.115'
						fill='#FFF'
					/>
					<path
						d='M50.88 78.773h18.24c2.512 0 4.574 2.41 4.574 5.351v25.68c0 2.94-2.063 5.35-4.575 5.35H50.881c-2.513 0-4.574-2.41-4.574-5.35v-25.68c0-2.942 2.06-5.351 4.574-5.351'
						fill='#A6A8AB'
					/>
					<path
						d='M52.531 80.252h14.935c2.706 0 4.919 2.214 4.919 4.915v23.595c0 2.701-2.215 4.917-4.919 4.917H52.531c-2.701 0-4.917-2.213-4.917-4.917V85.167c0-2.704 2.212-4.915 4.917-4.915'
						fill='#C5C7C9'
					/>
					<path
						d='M61.853 99.884h6.353c.2 0 .368.405.368.893v4.274c0 .488-.168.893-.368.893h-6.353c-.202 0-.371-.403-.371-.893v-4.274c0-.488.17-.893.37-.893'
						fill='#FFCA05'
					/>
					<path
						d='M61.118 87.985h7.819c.202 0 .366.403.366.891v4.275c0 .49-.164.89-.366.89h-7.82c-.202 0-.363-.4-.363-.89v-4.275c0-.488.161-.891.364-.891'
						fill='#FFF100'
					/>
					<path
						d='M50.749 99.884H57.1c.204 0 .37.405.37.893v4.274c0 .488-.166.893-.37.893H50.75c-.2 0-.367-.403-.367-.893v-4.274c0-.488.166-.893.367-.893'
						fill='#D6DE23'
					/>
					<path
						d='M50.017 87.985h7.819c.2 0 .365.403.365.891v4.275c0 .49-.166.89-.365.89h-7.82c-.2 0-.367-.4-.367-.89v-4.275c0-.488.167-.891.368-.891'
						fill='#FFF'
					/>
				</g>
			</svg>
		</PersonalityTypeIcon>
	);
};

export { BKIcon };
