import React from "react";
import { PersonalityTypeIcon } from "../PersonalityTypeIcon";

const RKIcon = () => {
	return (
		<PersonalityTypeIcon>
			<svg width='120' height='120' xmlns='http://www.w3.org/2000/svg'>
				<g fill='none' fillRule='evenodd'>
					<path
						d='M60 0c33.131 0 60 26.869 60 60 0 33.132-26.869 60-60 60S0 93.132 0 60C0 26.869 26.869 0 60 0'
						fill='#C1D9C4'
					/>
					<path
						d='m51.462 18.667 19.525.632L116.545 39.9A59.897 59.897 0 0 1 120 60c0 33.132-26.869 60-60 60-.504 0-1.007-.007-1.509-.02L51.462 18.667z'
						fill='#A4C6AE'
					/>
					<path
						d='M60.311 40.917c8.64 0 15.646 10.235 15.646 22.852 0 12.62-7.006 22.852-15.646 22.852S44.665 76.39 44.665 63.77c0-12.617 7.006-22.852 15.646-22.852'
						fill='#A16F48'
					/>
					<path
						d='m50.813 60.455 5.348.34c4.373 0 8.612-.058 13.022-.34l.912 6.94c.472 3.605 1.54 6.06 5.317 6.56 9.538 1.253 15.275 2.84 19.468 9.017 3.222 4.741 5.209 11.96 4.414 17.718-.35 2.546-1.012 4.595-1.977 6.287C87.072 115.127 74.105 120 60 120c-14.106 0-27.074-4.874-37.32-13.025-.963-1.69-1.624-3.741-1.974-6.285-.795-5.757 1.19-12.977 4.41-17.718 4.195-6.176 9.932-7.764 19.47-9.017 3.775-.5 4.845-2.955 5.317-6.56l.91-6.94z'
						fill='#EEB17B'
					/>
					<path
						d='M70.956 70.41c.708 2.021 1.787 3.193 4.456 3.545 9.538 1.253 15.321 2.84 19.514 9.017 2.74 4.033 5.054 9.86 5.21 15.053.231 2.063.322 4.154.335 6.263C89.8 114.045 75.595 120 60 120c-15.595 0-29.801-5.955-40.473-15.714.015-2.108.105-4.198.337-6.26.154-5.195 2.514-11.02 5.252-15.054 4.195-6.176 9.932-7.764 19.47-9.017 2.408-.32 3.714-1.434 4.47-3.134l10.815 26.182L70.956 70.41z'
						fill='#F2EBDB'
					/>
					<path
						d='M19.349 104.12c.112-7.41 1.17-14.64 5.59-21.148 4.018-5.921 9.625-7.623 18.484-8.861l10.268 34.556 6.227 11.331c-15.65-.02-29.897-6.04-40.57-15.877zm57.467-29.976c8.72 1.235 14.26 2.961 18.243 8.828 4.42 6.506 5.479 13.737 5.591 21.15-10.672 9.838-24.92 15.857-40.572 15.876l5.943-11.128 10.795-34.726z'
						fill='#485439'
					/>
					<path
						d='M45.854 54.36c1.691 4.603 4.256 8.108 7.453 10.823 5.9 5.007 7.81 4.977 13.692-.046 3.239-2.765 5.829-6.356 7.508-11.083.387.547 1.086.294 1.696-.162.695-.524 1.172-1.208 1.566-2.017 1.076-2.198 1.562-5.284.456-6.714a1.802 1.802 0 0 0-1.416-.71c.445-1.783.958-3.542 1.471-5.603 2.618-10.463-6.35-19.397-18.284-20.906C48.665 19.074 39.75 28.44 41.974 38.848c.448 2.088.974 3.854 1.443 5.643-.45.086-.866.298-1.192.67-1.244 1.43-.698 4.516.512 6.714.449.809.98 1.493 1.762 2.017.45.296.944.51 1.355.468'
						fill='#F4C99B'
					/>
					<path
						fill='#738445'
						d='m42.515 99.453 4.62-6.539-7.463 1.849-4.104-19.243 10.102-2.387 14.277 46.866-.156-.002-5.377-3.373zM77.48 99.453l-4.619-6.539 7.465 1.849 4.103-19.243-10.103-2.387-14.276 46.866.157-.002 5.376-3.373z'
					/>
					<path
						fill='#FBF6EE'
						d='m45.456 73.133 3.597-2.9L56.971 89.9l-13.125.968zM74.33 73.133l-3.597-2.9L62.814 89.9l13.126.968z'
					/>
					<path
						d='M44.414 52.75c-.17-.336-4.705-1.31-4.154-15.504.423-10.933 6.988-17.553 13.93-19.907 3.719-1.261 8.064-1.327 12.02-.147 8.815 2.628 12.787 9.878 13.961 17.933.65 4.444-.186 9.584-.675 12.713-1.02 6.5-3.974 6.516-6.843 11.567 1.866-3.252 2.706-6.35 2.87-9.236 1.02-18.26-10.945-13.568-12.66-21.9-4.118 11.662-18.7 1.56-17.518 26.009l-.931-1.527z'
						fill='#A16F48'
					/>
					<path
						d='m33.83 114.003.049-.478c1.133-11.162.935-11.429 1.37-.011l.046 1.178c-.492-.223-.98-.453-1.465-.689M84.765 114.664l.044-1.15c.422-11.418.23-11.15 1.337.01l.046.469c-.473.229-.948.453-1.427.671'
						fill='#283A28'
					/>
					<path
						d='M62.864 28.269c3.536.379 1.217 10.223-6.776 10.84 5.794-2.679 5.023-12.245 1.29-10.691.477-.08 5.486-.15 5.486-.15'
						fill='#A16F48'
					/>
				</g>
			</svg>
		</PersonalityTypeIcon>
	);
};

export { RKIcon };
