import { PersonalityType, PersonalityTypes } from "./PersonalityType";
import { RPIcon } from "../ui/icons/RPIcon";
import { RBIcon } from "../ui/icons/RBIcon";
import { RAIcon } from "../ui/icons/RAIcon";
import { RSIcon } from "../ui/icons/RSIcon";
import { RKIcon } from "../ui/icons/RKIcon";
import { PBIcon } from "../ui/icons/PBIcon";
import { PAIcon } from "../ui/icons/PAIcon";
import { PSIcon } from "../ui/icons/PSIcon";
import { PKIcon } from "../ui/icons/PKIcon";
import { BAIcon } from "../ui/icons/BAIcon";
import { BSIcon } from "../ui/icons/BSIcon";
import { BKIcon } from "../ui/icons/BKIcon";
import { ASIcon } from "../ui/icons/ASIcon";
import { AKIcon } from "../ui/icons/AKIcon";
import { SKIcon } from "../ui/icons/SKIcon";

const CombinedPersonalityTypes: {
	[key: string]: {
		types: PersonalityType[];
		type: string;
		icon: (arg: any) => JSX.Element;
		detailedType: {
			[key: string]: string;
		};
	};
} = {
	RP: {
		types: [PersonalityTypes.REALISTYCZNY, PersonalityTypes.PRZEDSIEBIORCZY],
		type: "Szef Europejskiego Banku",
		icon: RPIcon,
		detailedType: {
			rp: "typ realistyczno-przedsiębiorczy",
			pr: "typ przedsiębiorczo-realistyczny",
		},
	},
	RB: {
		types: [PersonalityTypes.REALISTYCZNY, PersonalityTypes.BADAWCZY],
		type: "Noblista w dziedzinie chemii",
		icon: RBIcon,
		detailedType: {
			rb: "typ realistyczno-badawczy",
			br: "typ badawczo-realistyczny",
		},
	},
	RA: {
		types: [PersonalityTypes.REALISTYCZNY, PersonalityTypes.ARTYSTYCZNY],
		type: "Architekt miast przyszłości",
		icon: RAIcon,
		detailedType: {
			ra: "typ realistyczno-artystyczny",
			ar: "typ artystyczno-realistyczny",
		},
	},
	RS: {
		types: [PersonalityTypes.REALISTYCZNY, PersonalityTypes.SPOLECZNY],
		type: "Światowej sławy neurochirurg",
		icon: RSIcon,
		detailedType: {
			rs: "typ realistyczno-społeczny",
			sr: "typ społeczno-realistyczny",
		},
	},
	RK: {
		types: [PersonalityTypes.REALISTYCZNY, PersonalityTypes.KONWENCJONALNY],
		type: "Dokumentalista w Archiwum X",
		icon: RKIcon,
		detailedType: {
			rk: "typ realistyczno-konwencjonalny",
			kr: "typ konwencjonalno-realistyczny",
		},
	},
	PB: {
		types: [PersonalityTypes.PRZEDSIEBIORCZY, PersonalityTypes.BADAWCZY],
		type: "Wynalazca leku na raka",
		icon: PBIcon,
		detailedType: {
			pb: "typ przedsiębiorczo-badawczy",
			bp: "typ badawczo-przedsiębiorczy",
		},
	},
	PA: {
		types: [PersonalityTypes.PRZEDSIEBIORCZY, PersonalityTypes.ARTYSTYCZNY],
		type: "Dyrektor artystyczny galerii sztuki",
		icon: PAIcon,
		detailedType: {
			pa: "typ przedsiębiorczo-artystyczny",
			ap: "typ artystyczno-przedsiębiorczy",
		},
	},
	PS: {
		types: [PersonalityTypes.PRZEDSIEBIORCZY, PersonalityTypes.SPOLECZNY],
		type: "Polityk światowego formatu",
		icon: PSIcon,
		detailedType: {
			ps: "typ przedsiębiorczo-społeczny",
			sp: "typ społeczno-przedsiębiorczy",
		},
	},
	PK: {
		types: [PersonalityTypes.PRZEDSIEBIORCZY, PersonalityTypes.KONWENCJONALNY],
		type: "Menedżer w międzynarodowej firmie",
		icon: PKIcon,
		detailedType: {
			pk: "typ przedsiębiorczo-konwencjonalny",
			kp: "typ konwencjonalno-przedsiębiorczy",
		},
	},
	BA: {
		types: [PersonalityTypes.BADAWCZY, PersonalityTypes.ARTYSTYCZNY],
		type: "Dziennikarz z nagrodą Pulitzera",
		icon: BAIcon,
		detailedType: {
			ba: "typ badawczo-artystyczny",
			ab: "typ artystyczno-badawczy",
		},
	},
	BS: {
		types: [PersonalityTypes.BADAWCZY, PersonalityTypes.SPOLECZNY],
		type: "Profesor psychologii na uniwersytecie",
		icon: BSIcon,
		detailedType: {
			bs: "typ badawczo-społeczny",
			sb: "typ społeczno-badawczy",
		},
	},
	BK: {
		types: [PersonalityTypes.BADAWCZY, PersonalityTypes.KONWENCJONALNY],
		type: "Pierwszy człowiek poza Układem Słonecznym",
		icon: BKIcon,
		detailedType: {
			bk: "typ badawczo-konwencjonalny",
			kb: "typ konwencjonalno-badawczy",
		},
	},
	AS: {
		types: [PersonalityTypes.ARTYSTYCZNY, PersonalityTypes.SPOLECZNY],
		type: "Publicysta",
		icon: ASIcon,
		detailedType: {
			as: "typ artystyczno-społeczny",
			sa: "typ społeczno-artystyczny",
		},
	},
	AK: {
		types: [PersonalityTypes.ARTYSTYCZNY, PersonalityTypes.KONWENCJONALNY],
		type: "Aktor filmu oskarowego",
		icon: AKIcon,
		detailedType: {
			ak: "typ artystyczno-konwencjonalny",
			ka: "typ konwencjonalno-artystyczny",
		},
	},
	SK: {
		types: [PersonalityTypes.SPOLECZNY, PersonalityTypes.KONWENCJONALNY],
		type: "Prawnik gwiazd rocka",
		icon: SKIcon,
		detailedType: {
			sk: "typ społeczno-konwencjonalny",
			ks: "typ konwencjonalno-społeczny",
		},
	},
};

export { CombinedPersonalityTypes };
