import React from "react";
import { PersonalityTypeIcon } from "../PersonalityTypeIcon";

const RPIcon = () => {
	return (
		<PersonalityTypeIcon>
			<svg width='120' height='120' xmlns='http://www.w3.org/2000/svg'>
				<g fill='none' fillRule='evenodd'>
					<path
						d='M60 0c33.131 0 60 26.868 60 60 0 33.13-26.869 60-60 60S0 93.13 0 60C0 26.868 26.869 0 60 0'
						fill='#C1C5D2'
					/>
					<path
						d='m51.462 18.667 19.525.63 45.558 20.604A59.9 59.9 0 0 1 120 60c0 33.13-26.869 60-60 60-.504 0-1.007-.007-1.509-.019L51.462 18.667z'
						fill='#A4A8B2'
					/>
					<path
						d='M15.435 84.69c-1.883 3.714-2.835 8.353-2.29 12.779C24.141 111.2 41.044 120 60 120c18.956 0 35.858-8.8 46.855-22.532.544-4.426-.406-9.065-2.292-12.779-7.603-14.993-27.617-12.513-30.207-16.362-2.011-2.99-.811-12.18-.948-14.544-9.366.895-17.36.7-26.723-.195-.135 2.365.972 11.75-1.041 14.739-2.59 3.849-22.603 1.369-30.209 16.362'
						fill='#DFA780'
					/>
					<path
						d='M15.435 84.692c-.063.123-.123.246-.184.37-1.527 3.145-2.533 6.965-3.196 11.007 10.95 14.532 28.352 23.932 47.945 23.932s36.994-9.4 47.945-23.934c-.663-4.04-1.668-7.86-3.196-11.005-.06-.124-.122-.247-.183-.37-2.632-5.184-6.746-8.278-11.108-10.267-4.216-2.244-11.101-4.734-15.227-6.244-2.783 6.945-9.52 12.684-17.594 12.684-7.248 0-17.95-6.885-20.07-12.192-4.121 1.508-9.801 3.504-14.013 5.744-4.245 1.935-8.253 4.913-10.892 9.842-.077.145-.152.286-.227.433'
						fill='#EDEDEE'
					/>
					<path
						d='M66.603 119.636 64.097 88.74l7.276-7.876-5.195-2.52c-1.75.413-3.612.634-5.544.634-2.238 0-4.386-.298-6.376-.846l-5.633 2.732 7.308 7.876-2.53 30.898c2.167.237 4.367.362 6.597.362a60.58 60.58 0 0 0 6.603-.364'
						fill='#C61B37'
					/>
					<path
						d='M52.494 66.35c-2.691-1.707-4.69-4.281-6.152-7.38-1.054-2.235-1.829-4.739-2.387-7.386-.335-.074-.694-.27-1.03-.512-.823-.595-1.384-1.377-1.85-2.3-1.277-2.506-1.848-6.028-.543-7.658.563-.705 1.38-.901 2.214-.785a73.861 73.861 0 0 1-.036-2.427c0-4.676.016-10.412 1.593-13.682 1.153-2.397 2.904-3.963 4.975-5.122 7.932-4.435 13.512-4.435 21.446 0 2.069 1.159 3.818 2.725 4.97 5.122 1.579 3.27 1.595 9.006 1.595 13.682 0 .802-.01 1.614-.032 2.427.832-.116 1.647.08 2.212.785 1.305 1.63.732 5.152-.543 7.659-.465.922-1.028 1.704-1.852 2.299-.336.242-.695.438-1.03.512-.556 2.647-1.33 5.151-2.383 7.387-1.464 3.098-3.464 5.672-6.155 7.379-4.227 2.675-10.785 2.675-15.012 0'
						fill='#FCCA9A'
					/>
					<path
						d='M40.555 40.074c-1.433-9.25-.54-18.374 9.731-23.395 5.42-2.644 13.958-2.555 19.426 0 10.34 4.832 11.042 13.998 9.732 23.395-.182 1.965-2.975 3.091-3.938 5.721.956-3.051-1.21-14.766-2.933-18.061-.814-1.553-2.659-2.748-4.404-2.224-3.776 1.068-5.901 2.904-8.258 2.837-2.082-.057-4.342-1.895-8.08-2.837-1.743-.524-3.591.671-4.403 2.224-1.727 3.28-3.886 15.024-2.934 18.061-.961-2.63-3.76-3.756-3.939-5.72'
						fill='#F1F1F2'
					/>
					<path
						fill='#F7F7F8'
						d='M74.24 67.052 60.042 79.066l10.522 10.242 9.251-20.823zM45.824 67.052 60.02 79.066 49.5 89.308l-9.253-20.823z'
					/>
					<path
						d='M107.945 96.067c-.663-4.04-1.668-7.86-3.196-11.005a92.492 92.492 0 0 0-.183-.37c-2.631-5.184-6.746-8.278-11.108-10.267-3.247-1.728-8.08-3.601-12.014-5.062-1.527 13.85-3.847 34.843-7.135 48.914 13.633-3.338 25.441-11.335 33.636-22.21m-92.51-11.375-.184.37c-1.527 3.145-2.533 6.965-3.196 11.007 8.194 10.873 20 18.868 33.633 22.206-3.284-14.055-5.602-35.018-7.13-48.869-3.83 1.398-8.441 3.117-12.005 5.011-4.244 1.934-8.251 4.913-10.891 9.842-.077.145-.152.286-.227.433'
						fill='#3C3731'
					/>
					<path
						d='M38.693 68.755c1.808 14.744 4.592 34.387 7.593 49.667a59.55 59.55 0 0 1-10.458-3.497L24.872 90.498l9.245-8.794-8.044-2.316 5.713-7.71 6.907-2.923zM81.306 68.755c-1.804 14.744-4.59 34.387-7.59 49.666a59.557 59.557 0 0 0 10.458-3.498l10.955-24.425-9.243-8.794 8.042-2.316-5.713-7.71-6.909-2.923z'
						fill='#67605A'
					/>
					<path
						d='m27.721 106.068-.415 4.243.621.4-.206-4.643zM92.278 106.068l.415 4.243c-.206.134-.412.267-.62.399l.205-4.642z'
						fill='#1B1711'
					/>
				</g>
			</svg>
		</PersonalityTypeIcon>
	);
};

export { RPIcon };
