import React from "react";
import { makeStyles } from "@material-ui/core";
import { Box } from "../../atoms/Box";
import { Typography } from "../../atoms/Typography";
import { Card } from "../../atoms/Card";
import { Container } from "../../atoms/Container";
import { Colors, theme } from "../../theme";
import { PersonalityType } from "../../../models/PersonalityType";
import { getCombinedPersonalityType } from "../../../models/getCombinedPersonalityType";
import { getDetailedCombinedPersonalityType } from "../../../models/getDetailedCombinedPersonalityType";

const useStyles = makeStyles({
	card: {
		border: "none",
		borderRadius: "0.75rem",
		background: Colors.PersonalityTypeHighlightBackground,
		[theme.breakpoints.down("sm")]: {
			padding: "2rem",
		},
		[theme.breakpoints.up("sm")]: {
			padding: "2rem",
			position: "relative",
			overflow: "visible",
			marginTop: "8.8125rem",
		},
	},
	headerContainer: {
		[theme.breakpoints.down("sm")]: {
			marginTop: "22.75",
		},
		[theme.breakpoints.up("sm")]: {
			marginTop: "4rem",
		},
	},
	headerText: {
		[theme.breakpoints.up("md")]: {
			fontSize: "2rem",
		},
	},
	iconContainer: {
		[theme.breakpoints.up("sm")]: {
			position: "absolute",
			top: "-5.3125rem",
			marginBottom: "-5.3125rem",
		},
	},
	descriptionContainer: {
		[theme.breakpoints.up("sm")]: {
			marginTop: "3.8125rem",
		},
	},
	boldText: {
		fontWeight: 600,
		color: Colors.Green,
	},
	lightText: {
		fontWeight: 500,
		color: Colors.Green,
	},
});

interface PersonalityTypeHighlight {
	firstType: PersonalityType;
	secondType: PersonalityType;
}

const PersonalityTypeHighlight = (props: PersonalityTypeHighlight) => {
	const { firstType, secondType } = props;
	const styles = useStyles();
	const combinedPersonalityType = getCombinedPersonalityType(firstType, secondType);
	const detailedCombinedPersonalityType = getDetailedCombinedPersonalityType(
		firstType,
		secondType,
		combinedPersonalityType
	);

	return (
		<Container maxWidth={"sm"}>
			<Box marginBottom={3} className={styles.headerContainer}>
				<Typography className={styles.headerText} variant={"h3"} align={"center"}>
					Twój typ zawodowy to:
				</Typography>
			</Box>
			<Card variant={"outlined"} className={styles.card}>
				<Box
					display={"flex"}
					justifyContent={"center"}
					alignItems={"center"}
					flexDirection={"column"}
				>
					<Box className={styles.iconContainer}>
						{React.createElement(combinedPersonalityType.icon)}
					</Box>
					<Box marginTop={1.5} className={styles.descriptionContainer}>
						<Typography
							variant={"h3"}
							component={"h2"}
							align={"center"}
							className={styles.boldText}
						>
							{combinedPersonalityType.type}
						</Typography>
						<Box marginTop={1}>
							<Typography variant={"body2"} className={styles.lightText} align={"center"}>
								{detailedCombinedPersonalityType}
							</Typography>
						</Box>
					</Box>
				</Box>
			</Card>
		</Container>
	);
};

export { PersonalityTypeHighlight };
