import { QuizResult } from "../models/QuizResult";

function _isInteger(value: string | number) {
	return Boolean(Number.isInteger(Number(value)));
}

function _hasAllParams(params: ReturnType<typeof paramsFromUrl>) {
	const paramsArray = Object.values(params);
	return paramsArray.every(_isInteger);
}

function paramsFromUrl(location: { search: string }): QuizResult | null {
	const searchParams = new URLSearchParams(location.search);
	const params = [];

	// @ts-ignore
	for (let entry of searchParams.entries()) {
		params.push(entry);
	}

	const parsedParams = params.map((param) => [param[0], Number(param[1])]);

	const { re, ar, ba, ko, pr, sp } = Object.fromEntries(parsedParams);

	const Realistyczny = re;
	const Artystyczny = ar;
	const Badawczy = ba;
	const Konwencjonalny = ko;
	const Przedsiębiorczy = pr;
	const Społeczny = sp;

	const decodedParams = {
		Realistyczny,
		Artystyczny,
		Badawczy,
		Konwencjonalny,
		Przedsiębiorczy,
		Społeczny,
	};

	const hasAllParams = _hasAllParams(decodedParams);

	return params.length && hasAllParams ? decodedParams : null;
}

export { paramsFromUrl };
