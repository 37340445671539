import React from "react";
import { useLocation } from "@reach/router";
import { makeStyles } from "@material-ui/core";
import { Layout } from "../Layout";
import { Container } from "../../atoms/Container";
import { Box } from "../../atoms/Box";
import { PersonalityTypeHighlight } from "../../molecules/PersonalityTypeHighlight";
import { PersonalityTypeSummary } from "../../molecules/PersonalityTypeSummary";
import { Grid } from "../../atoms/Grid";
import { RadarChart } from "../../atoms/RadarChart";
import { Typography } from "../../atoms/Typography";
import { ShareQuizResult } from "../../molecules/ShareQuizResult";
import { Colors, theme } from "../../theme";
import { useQuizResult } from "../../../context/QuizResult";
import { getPersonalityTypePoints } from "../../../models/getPersonalityTypePoints";
import { getDominantPersonalityTypes } from "../../../models/getDominantPersonalityTypes";
import { useSaveResultToUrl } from "../../../hooks/useSaveResultToUrl";
import { paramsFromUrl } from "../../../utils/paramsFromUrl";
import { AllPersonalityTypesButton } from "../../atoms/AllPersonalityTypesButton";

const useStyles = makeStyles({
	chartContainer: {
		maxWidth: "18.75rem",
		width: "100%",
	},
	shareResultContainer: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		[theme.breakpoints.down("sm")]: {
			marginTop: "3.75rem",
			marginBottom: "3.75rem",
		},
		[theme.breakpoints.up("sm")]: {
			marginTop: "7.5rem",
			marginBottom: "7.5rem",
		},
	},
	personalityTypeSummary: {
		background: "none",
	},
	personalityTypeSummaryContainer: {
		background: Colors.Background,
		borderRadius: "0.75rem",
	},
	paragraph: {
		fontSize: "1rem",
	},
});

const QuizResultTemplate = () => {
	const styles = useStyles();
	const { state } = useQuizResult();
	const location = useLocation();
	const resultFromState = getPersonalityTypePoints(state);
	const resultFromUrl = paramsFromUrl(location);
	const quizResult = resultFromUrl || resultFromState;
	const dominant = getDominantPersonalityTypes(quizResult);
	useSaveResultToUrl(resultFromUrl, resultFromState);

	const firstPersonalityType = dominant[0];
	const secondPersonalityType = dominant[1];

	return (
		<Layout isResultPage={true} showDownloadMobiles={true}>
			<Container maxWidth={"lg"}>
				<main>
					<PersonalityTypeHighlight
						firstType={firstPersonalityType}
						secondType={secondPersonalityType}
					/>
					<Box marginY={5}>
						<Typography className={styles.paragraph} variant={"body1"} align={"center"}>
							To oznacza, że łączysz w sobie przede wszystkim elementy tych typów:
						</Typography>
					</Box>
					<Grid container spacing={4} direction={"row"}>
						<Grid item sm={12} md={6}>
							<PersonalityTypeSummary type={firstPersonalityType} number={1} />
						</Grid>
						<Grid item sm={12} md={6}>
							<PersonalityTypeSummary type={secondPersonalityType} number={2} />
						</Grid>
					</Grid>
					<Box
						display={"flex"}
						alignItems={"center"}
						justifyContent={"center"}
						marginX={2}
					>
						<Container maxWidth={"xs"}>
							<RadarChart quizResult={quizResult} />
						</Container>
					</Box>
					<Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
						<AllPersonalityTypesButton />
					</Box>
					<Container maxWidth={"md"} className={styles.shareResultContainer}>
						<ShareQuizResult />
					</Container>
				</main>
			</Container>
		</Layout>
	);
};

export { QuizResultTemplate };
