import React from "react";
import { PersonalityTypeIcon } from "../PersonalityTypeIcon";

const AKIcon = () => {
	return (
		<PersonalityTypeIcon>
			<svg
				width='120'
				height='120'
				xmlns='http://www.w3.org/2000/svg'
				xmlnsXlink='http://www.w3.org/1999/xlink'
			>
				<defs>
					<path id='a' d='M0 0h120v120H0z' />
					<path id='c' d='M0 0h68.539v101.334H0z' />
				</defs>
				<g fill='none' fillRule='evenodd'>
					<g>
						<mask id='b' fill='#fff'>
							<use xlinkHref='#a' />
						</mask>
						<path
							d='M60 0c33.132 0 60 26.868 60 60 0 33.131-26.868 60.001-60 60.001-33.131 0-60-26.87-60-60C0 26.867 26.869 0 60 0'
							fill='#B1CAC2'
							mask='url(#b)'
						/>
					</g>
					<g transform='translate(51.461 18.667)'>
						<mask id='d' fill='#fff'>
							<use xlinkHref='#c' />
						</mask>
						<path
							d='m0 0 19.526.63 45.558 20.604a59.884 59.884 0 0 1 3.455 20.1c0 33.13-26.868 60-60 60-.504 0-1.007-.007-1.508-.02L0 0z'
							fill='#8FB4AE'
							mask='url(#d)'
						/>
					</g>
					<path
						d='M78.742 91.764c2.266 7.762-10.16-7.027-19.064-7.027-8.903 0-24.13-2-24.23-13.152-.33-36.105 35.549-46.923 42.545-41.418 14.61 11.498-.916 55.884.749 61.597'
						fill='#3A2315'
					/>
					<path
						d='m50.814 60.455 5.35.343c4.372 0 8.61-.06 13.02-.343l.91 6.942c.474 3.602 1.543 6.06 5.32 6.557 9.534 1.256 15.452 2.841 19.645 9.019 3.218 4.743 5.92 11.963 5.125 17.72-.282 2.039-.821 3.76-1.585 5.236C88.164 114.707 74.7 120 60 120c-14.7 0-28.163-5.294-38.597-14.072-.766-1.475-1.307-3.197-1.588-5.236-.796-5.757 1.904-12.977 5.125-17.72 4.192-6.178 10.107-7.763 19.646-9.02 3.776-.497 4.847-2.954 5.317-6.556l.911-6.942z'
						fill='#A97C5C'
					/>
					<path
						d='M75.413 73.953c-3.728-.49-4.71-6.21-5.195-9.733-2.98.872-6.427 1.381-10.112 1.381-3.693 0-7.14-.509-10.12-1.383-.48 3.526-1.674 9.245-5.4 9.735-6.425.846-11.206 1.842-14.924 4.291l-.015.007c-8.125 2.965-8.856 16.198-8.548 27.421 10.476 8.933 24.058 14.329 38.901 14.329 14.72 0 28.2-5.307 38.641-14.109.463-16.194-.924-28.999-23.228-31.939'
						fill='#FAE4D1'
					/>
					<path
						d='M45.07 51.931c1.627 5.828 4.502 10.08 8.239 13.251 5.9 5.01 7.808 4.977 13.691-.044 3.724-3.18 6.586-7.445 8.204-13.28.396.369 1.022.114 1.574-.312.694-.533 1.167-1.239 1.564-2.066 1.074-2.253 1.559-5.42.455-6.884a1.774 1.774 0 0 0-1.31-.726c.259-.957.525-1.95.795-3.023 2.617-10.463-6.35-19.394-18.286-20.906-11.331 1.134-20.245 10.498-18.02 20.906.412 1.94.786 2.17-.403 3.749-1.103 1.464-.617 4.631.458 6.884.396.827.869 1.533 1.565 2.066.51.392 1.076.64 1.473.385'
						fill='#D7A581'
					/>
					<path
						d='M72.89 73.13c.27.164.57.309.898.433.094 1.53.142 3.097.142 4.69v.012c0 9.067-1.536 17.286-4.02 23.245-2.518 6.054-6.02 9.795-9.908 9.8v.003h-.006v-.004c-3.886-.004-7.39-3.747-9.909-9.8-2.482-5.958-4.018-14.174-4.018-23.24V78.256c0-1.594.05-3.16.141-4.693a5.24 5.24 0 0 0 .898-.434 78.108 78.108 0 0 0-.172 5.127h.005v.013h-.005c.005 8.824 1.49 16.808 3.894 22.576 2.363 5.677 5.605 9.188 9.166 9.188v-.003h.006v.003c3.563 0 6.805-3.511 9.169-9.185 2.403-5.77 3.89-13.755 3.89-22.583h-.002v-.012h.003a76.79 76.79 0 0 0-.173-5.124'
						fill='#604339'
					/>
					<path
						d='M72.6 72.938c.28.196.585.372.93.52.258 2.847.4 5.826.4 8.885v.017c0 10.184-1.533 19.417-4.005 26.111-2.516 6.81-6.024 11.022-9.923 11.022v.002h-.006v-.002c-3.897 0-7.403-4.216-9.92-11.022-2.475-6.69-4.007-15.922-4.007-26.105v-.018c0-3.062.14-6.042.405-8.892.34-.146.648-.322.922-.518a95.69 95.69 0 0 0-.46 9.41h.005v.018h-.005c.005 9.959 1.495 18.976 3.902 25.495 2.366 6.399 5.609 10.36 9.158 10.36v-.002h.006v.002c3.55 0 6.793-3.961 9.155-10.36h.003c2.411-6.516 3.902-15.533 3.902-25.5h-.003V82.342h.003c0-3.253-.162-6.406-.463-9.405'
						fill='#604339'
					/>
					<path
						d='M50.823 119.302a46.974 46.974 0 0 1-.76-2.164c-2.467-7.602-3.994-18.098-3.994-29.677v-.02c0-4.97.285-9.745.8-14.174.363-.196.684-.423.966-.684-.58 4.608-.893 9.618-.899 14.858h.006v.02h-.006c.006 11.383 1.497 21.677 3.914 29.129.327 1.01.674 1.964 1.034 2.86a71.7 71.7 0 0 1-1.061-.148m21.341-46.72c.282.262.6.489.966.685.515 4.426.8 9.202.8 14.171v.016c0 11.583-1.529 22.082-3.994 29.684-.245.75-.498 1.47-.76 2.164-.351.053-.706.1-1.06.149.36-.897.704-1.85 1.032-2.861 2.418-7.452 3.914-17.752 3.914-29.136h-.003v-.016h.003c-.003-5.24-.319-10.249-.898-14.855'
						fill='#66402E'
					/>
					<path
						d='M19.923 94.709c.857-6.694 3.378-15.245 4.496-16.992 1.606-2.511 3.803-.668 6.092-1.072 4.193-.74 5.842-2.435 12.537-2.833 9.327 3.616 3.126 38.825 8.044 45.528-11.848-1.765-22.564-6.994-31.088-14.624.011-.87.032-1.72.071-2.562-.515-2.449-.469-4.979-.152-7.445'
						fill='#A06F4E'
					/>
					<path
						d='m48.017 71.125-7.225 2.455-1.5 7.769 3.538 6.95-7.641 3.67 11.14 26.463c2.189.511 4.423.898 6.698 1.162l-5.01-48.47z'
						fill='#C38C69'
					/>
					<path
						d='M34.377 68.277s-2.238 2.475-3.876-1.341c2.68.691 5.783-15.624 5.651-19.944-.318-10.318 5.086-17.403 11.416-22.189 7.851-5.933 9-6.714 9-6.714s-2.209 4.349-6.952 10.782c-4.144 1.668-6.167 26.169 1.61 24.226-2.294 3.105-6.18 2.806-9.056-.016.267 1.47-5.453 30.284 2.767 26.91-1.389 2.433-4.603 1.968-7.034.926-3.132-2.2-2.632-6.228-2.632-6.228s-4.015 2.072-5.605-2.701c2.758.747 4.71-3.711 4.71-3.711'
						fill='#3A2315'
					/>
					<path
						d='M38.23 62.504c-2.668 4.661 1.465 5.698 1.465 5.698s-2.187 3.308-.402 6.689c1.696 2.035 4.96 2.642 7.305 1.106-3.821-.315-2.823-4.398-.423-9.05a25.834 25.834 0 0 1-4.788-3.203c-.738.26-1.756.055-3.158-1.24M74.242 66.64c-2.667 4.66 1.462 5.698 1.462 5.698s-2.188 3.307-.401 6.691c1.694 2.031 4.962 2.638 7.306 1.102-3.821-.313-2.823-4.394-.423-9.047a26.055 26.055 0 0 1-4.79-3.202c-.736.258-1.756.053-3.154-1.242'
						fill='#3A2315'
					/>
					<path
						d='M100.086 94.709c-.858-6.694-3.378-15.245-4.497-16.992-1.606-2.511-3.803-.668-6.092-1.072-4.194-.74-5.842-2.435-12.537-2.833-9.325 3.616-3.128 38.82-8.041 45.527 11.847-1.766 22.561-6.999 31.085-14.629a76.346 76.346 0 0 0-.071-2.556c.516-2.449.47-4.979.153-7.445'
						fill='#A06F4E'
					/>
					<path
						d='m71.992 71.125 7.224 2.455 1.5 7.769-3.538 6.95 7.643 3.67-11.139 26.46c-2.19.512-4.426.9-6.7 1.164l5.01-48.468z'
						fill='#C38C69'
					/>
					<path
						d='m33.827 114.002.049-.478c1.134-11.16.936-11.427 1.371-.009l.045 1.177c-.492-.223-.98-.454-1.465-.69M84.762 114.666l.043-1.15c.423-11.419.231-11.152 1.338.008l.047.47c-.473.23-.949.454-1.428.672'
						fill='#78503A'
					/>
					<path
						d='M64.475 69.96c7.662 2.311 8.333-11.47 8.397-14.8.209-10.924 10.997-.023 11.17 0-.868 3.632-.076 14.567 3.43 13.824-.744 3.802-4.05 3.029-4.642 2.698.009.895.348 2.9 3.632 2.507-.723 2.29-3.146 4.242-5.623 3.807-1.657-.384-2.71-2.183-2.94-4.326-1.31 1.945-3.197 8.65.517 11.247-5.875-.476-7.172-8.596-7.176-12.112-1.975 1.47-4.875.622-6.765-2.844'
						fill='#3A2315'
					/>
					<path
						d='M44.515 31.685c.362 11.374 8.335 13.25 16.968 13.789-5.01-2.792-3.002-9.37-3.002-9.37s3 7.189 9.383 6.684c6.81.27 6.993 14.345-2.026 18.056 10.96 3.36 14.285-9.207 14.352-17.769.044-5.995-3.76-9.865-6.785-14.36-5.066-7.525-10.69-12.232-16.633-11.838-5.946.4-12.542 5.834-12.257 14.808'
						fill='#3A2315'
					/>
					<path
						d='M76.98 24.816c-1.104-8.315-8.206-14.734-16.818-14.734-8.619 0-15.716 6.419-16.821 14.734 4.76-.943 10.551-1.504 16.821-1.504 6.267 0 12.06.56 16.819 1.504'
						fill='#604339'
					/>
					<path
						d='M43.603 30.808c5.127-1.177 10.708-1.83 16.559-1.83 5.849 0 11.43.653 16.557 1.83.271-1.205.427-2.452.427-3.738 0-.767-.07-1.515-.165-2.254-4.758-.943-10.552-1.504-16.819-1.504-6.27 0-12.062.56-16.82 1.504-.097.739-.166 1.487-.166 2.254 0 1.286.156 2.533.427 3.738'
						fill='#472E24'
					/>
					<path
						d='M89.358 34.66c0-4.535-13.066-8.21-29.196-8.21-16.131 0-29.2 3.675-29.2 8.21 0 1.867 2.24 3.583 5.973 4.964 3.714-3.06 12.704-5.222 23.23-5.222 10.522 0 19.51 2.163 23.223 5.222 3.732-1.38 5.97-3.097 5.97-4.963'
						fill='#80584D'
					/>
				</g>
			</svg>
		</PersonalityTypeIcon>
	);
};

export { AKIcon };
