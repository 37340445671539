import React from "react";
import { PersonalityTypeIcon } from "../PersonalityTypeIcon";

const SKIcon = () => {
	return (
		<PersonalityTypeIcon>
			<svg width='120' height='120' xmlns='http://www.w3.org/2000/svg'>
				<g fill='none' fillRule='evenodd'>
					<path
						d='M60 0c33.131 0 60 26.868 60 60 0 33.131-26.869 60.001-60 60.001-33.132 0-60-26.87-60-60C0 26.867 26.868 0 60 0'
						fill='#FDE598'
					/>
					<path
						d='m51.462 13.706 19.525.631 42.99 19.443A59.751 59.751 0 0 1 120 60c0 33.131-26.869 60.001-60 60.001-.504 0-1.007-.007-1.508-.02l-7.03-106.275z'
						fill='#F2C96D'
					/>
					<path
						d='M15.436 84.69c-1.885 3.714-2.836 8.351-2.293 12.777 10.997 13.734 27.9 22.534 46.857 22.534s35.86-8.8 46.856-22.532c.543-4.427-.407-9.065-2.292-12.778-7.604-14.994-27.618-12.514-30.208-16.363-2.01-2.989-.813-12.18-.947-14.543-9.367.894-17.36.698-26.723-.196-.136 2.365.968 11.75-1.043 14.739-2.59 3.85-22.603 1.37-30.207 16.363'
						fill='#B68C67'
					/>
					<path
						d='M77.747 39.449c.024-.834.037-1.667.037-2.493 0-1.175 0-5.398-.027-6.648-3.783-1.927-10.325-3.196-17.76-3.196-7.435 0-13.974 1.269-17.76 3.196-.024 1.25-.024 5.473-.024 6.648 0 .826.014 1.659.036 2.493-.857-.12-1.698.062-2.277.781-1.345 1.662-.755 5.251.554 7.804.482.94 1.06 1.736 1.907 2.344.347.248.716.44 1.06.519.574 2.697 1.367 5.253 2.454 7.529 1.503 3.157 3.56 5.78 6.33 7.519 4.348 2.727 11.095 2.727 15.441 0 2.772-1.74 4.828-4.362 6.333-7.52 1.084-2.275 1.881-4.83 2.455-7.528.343-.079.711-.271 1.057-.519.848-.608 1.426-1.405 1.907-2.344 1.312-2.553 1.9-6.142.556-7.804-.58-.719-1.42-.902-2.279-.781z'
						fill='#E0B287'
					/>
					<path
						d='M58.026 52.417a3.514 3.514 0 0 0-1.539.32c-1.244.544-2.164 1.624-3.333 2.358-1.785 1.13-2.9.134-3.656-.505.7 1.97 2.894 2.837 5.012 2.957 2.054.111 3.941-.404 5.284-2.026.08-.097.149-.195.214-.292v-2.172c-.43-.405-1.14-.643-1.982-.64'
						fill='#865733'
					/>
					<path
						d='M61.925 52.417c.495.004.928.052 1.54.32 1.242.544 2.162 1.624 3.332 2.358 1.785 1.13 2.902.134 3.657-.505-.702 1.97-2.897 2.837-5.014 2.957-2.053.111-3.942-.404-5.283-2.026a3.355 3.355 0 0 1-.214-.292v-2.172c.427-.405 1.142-.643 1.982-.64'
						fill='#865733'
					/>
					<path
						d='M15.434 84.693c-.063.122-.123.246-.182.37-1.528 3.145-2.535 6.964-3.198 11.007C23.004 110.6 40.406 120 60 120c19.594 0 36.995-9.4 47.946-23.931-.665-4.043-1.67-7.862-3.199-11.008a14.141 14.141 0 0 0-.182-.37c-2.63-5.183-6.744-8.277-11.108-10.267-4.215-2.243-11.1-4.734-15.225-6.244-2.785 6.946-9.522 12.685-17.596 12.685-7.248 0-17.95-6.886-20.071-12.192-4.12 1.508-9.799 3.504-14.013 5.743-4.243 1.935-8.251 4.913-10.89 9.842-.078.145-.153.286-.228.434'
						fill='#EDEDEE'
					/>
					<path
						d='M15.434 84.693c-.063.122-.123.246-.182.37-1.528 3.145-2.535 6.964-3.198 11.007C23.004 110.6 40.406 120 60 120c19.594 0 36.995-9.4 47.946-23.931-.665-4.043-1.67-7.862-3.199-11.008a14.141 14.141 0 0 0-.182-.37c-2.63-5.183-6.744-8.277-11.108-10.267-3.59-1.911-9.116-3.998-13.223-5.509l-19.98 49.093-20.025-49.211c-4.09 1.494-9.576 3.441-13.677 5.62-4.243 1.934-8.251 4.912-10.89 9.841-.078.145-.153.286-.228.434'
						fill='#D5D4CD'
					/>
					<path
						d='M15.434 84.693c-.063.122-.123.246-.182.37-1.214 2.5-2.098 5.425-2.743 8.55v3.049c10.974 14.194 28.165 23.34 47.491 23.34 19.325 0 36.515-9.145 47.49-23.34v-3.049c-.644-3.125-1.528-6.05-2.743-8.55a14.141 14.141 0 0 0-.182-.37c-2.63-5.184-6.744-8.278-11.108-10.268-2.574-1.37-6.146-2.83-9.474-4.105L60 116.989 36.028 70.34c-3.215 1.203-6.668 2.584-9.476 4.076-4.243 1.935-8.251 4.913-10.89 9.842-.078.145-.153.286-.228.434'
						fill='#CECFCD'
					/>
					<path
						fill='#FBFBFB'
						d='m74.236 67.052-14.193 13.92 10.522 6.558 9.25-19.045zM45.824 67.052l14.196 13.92-10.522 6.558-9.252-19.045z'
					/>
					<path
						d='M107.946 96.07c-.665-4.043-1.67-7.862-3.199-11.008a16.72 16.72 0 0 0-.182-.37c-2.63-5.183-6.744-8.277-11.107-10.267-3.247-1.727-8.081-3.601-12.015-5.061-1.528 13.85-3.847 34.842-7.135 48.914 13.634-3.338 25.442-11.333 33.638-22.208M15.434 84.693c-.063.122-.123.246-.182.37-1.528 3.145-2.535 6.964-3.198 11.007 8.194 10.873 20.001 18.869 33.634 22.207-3.285-14.057-5.602-35.02-7.13-48.87-3.831 1.397-8.441 3.116-12.006 5.01-4.243 1.935-8.251 4.913-10.89 9.842-.078.145-.153.286-.228.434'
						fill='#3C4464'
					/>
					<path
						d='M38.694 68.755c1.804 14.745 4.592 34.388 7.59 49.668a59.587 59.587 0 0 1-10.458-3.5L24.87 90.5l9.244-8.795-8.043-2.316 5.712-7.71 6.91-2.923zM81.307 68.755c-1.807 14.745-4.593 34.388-7.592 49.668a59.642 59.642 0 0 0 10.458-3.497L95.13 90.499l-9.245-8.795 8.043-2.316-5.712-7.71-6.909-2.923z'
						fill='#535A7A'
					/>
					<path
						d='m27.72 106.069-.415 4.242c.206.134.412.268.62.398l-.205-4.64zM92.28 106.069l.413 4.242c-.206.135-.412.268-.62.4l.206-4.642z'
						fill='#30324C'
					/>
					<path
						d='M71.909 15.776c2.28-.214 6.384-1.912 9.583 4.03 2.672 4.96 1.374 12.599-1.75 18.414-1.836 3.423-3.148 7.253-3.027 10.107-.83-2.615.122-6.815.247-9.505.243-5.23-1.109-11.702-5.373-12.008-.148-1.808-2.912-10.139-1.004-10.595l1.324-.443z'
						fill='#865733'
					/>
					<path
						d='M64.53 10.823c-9.557-1.716-20.659.639-25.341 8.296-6.98 13.76 3.169 19.727 2.968 29.39.636-2.436 0-7.605 2.591-10.835 2.459-3.06 4.767-3.686 9.81-4.217 10.35-1.08 16.926.636 19.291-7.046 0-.607.613-5.994.613-5.994.09-3.793-2.262-7.538-9.932-9.594'
						fill='#865733'
					/>
					<path
						d='M69.444 28.976s1.032 6.457-7.368 6.747c12.287 2.235 13.731-9.268 13.731-9.268l-6.363 2.52z'
						fill='#865733'
					/>
					<path
						d='M75.757 42.96c-.14.786-.358 1.492-.68 2.098-.42.783-.9 1.21-1.7 1.578-1.983.914-3.945 1.074-5.877.479-.93-.288-1.627-.661-2.305-1.35-.81-.816-1.294-1.749-1.613-2.739-.6-1.874.131-3.23 2.163-3.827 1.354-.4 3.005-.44 4.188-.524a37.893 37.893 0 0 1 3.346-.069c.498.011.93.086 1.289.228 1.672.667 1.433 2.718 1.189 4.126m-19.344.066c-.315.99-.797 1.923-1.609 2.74-.68.688-1.376 1.06-2.308 1.349-1.93.595-3.89.435-5.877-.48-.798-.366-1.276-.794-1.7-1.577-.32-.606-.536-1.312-.673-2.098-.252-1.408-.49-3.459 1.187-4.126.358-.142.787-.217 1.289-.228a37.738 37.738 0 0 1 3.341.07c1.188.082 2.835.123 4.19.523 2.032.598 2.765 1.953 2.16 3.827m22.369-4.621c-.157-.87-.712-.858-1.408-.906a47.637 47.637 0 0 0-11.38.46c-7.291 1.182-4.695 1.182-11.986 0a47.657 47.657 0 0 0-11.38-.46c-.697.048-1.251.035-1.412.906-.09.508.07.794.2.989.142.224.406.373.747.739.823.87.802 3.086 1.243 4.267.326.865.726 1.548 1.21 2.076 1.073 1.169 2.572 1.627 4.664 1.831 1.936.197 3.312.03 4.473-.558 1.165-.587 2.109-1.603 3.177-3.106.765-1.076 1.056-2.77 2.125-3.286.348-.168 1.543-.168 1.892 0 1.063.515 1.358 2.21 2.12 3.286 1.069 1.503 2.016 2.519 3.176 3.106 1.166.589 2.544.755 4.477.558 2.09-.204 3.588-.662 4.66-1.83.489-.529.885-1.212 1.21-2.077.444-1.18.426-3.396 1.248-4.267.341-.366.603-.515.747-.74.13-.194.289-.48.197-.988'
						fill='#FFEFBB'
					/>
				</g>
			</svg>
		</PersonalityTypeIcon>
	);
};

export { SKIcon };
