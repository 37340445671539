import React from "react";
import { Colors, TColors } from "../../theme";
import { SocialIcon } from "../SocialIcon";

interface WhatsAppIconProps {
	color?: TColors;
}

const WhatsAppIcon = (props: WhatsAppIconProps) => {
	const { color } = props;
	return (
		<SocialIcon color={color || Colors.Text}>
			<g transform='translate(-658.5 -6)' fillRule='evenodd'>
				<g transform='translate(502.5 6)'>
					<path d='M172.067 0a15.868 15.868 0 0 1 11.27 4.65A15.722 15.722 0 0 1 188 15.868c-.004 8.638-6.98 15.685-15.616 15.854l-.317.003a15.998 15.998 0 0 1-7.207-1.713l-.414-.217L156 32l2.26-8.217a15.77 15.77 0 0 1-2.126-7.927C156.137 7.113 163.284 0 172.067 0zm.005 2.945c-7.157 0-12.979 5.792-12.982 12.912 0 2.277.597 4.499 1.732 6.456l.252.415.315.499.032.213-1.217 4.419 4.566-1.193.203.029.484.285a12.997 12.997 0 0 0 6.14 1.792l.47.008.369-.005c6.86-.191 12.407-5.712 12.602-12.54l.005-.368a12.802 12.802 0 0 0-3.797-9.135 12.91 12.91 0 0 0-8.723-3.78l-.45-.007zm-5.646 5.588a16.056 16.056 0 0 1 .813.015h.106c.22.004.481.061.74.68.332.793 1.128 2.743 1.227 2.941.1.198.166.43.033.694-.132.265-.199.43-.398.661-.199.232-.418.517-.597.694-.2.198-.407.412-.174.809.232.397 1.03 1.694 2.214 2.745 1.52 1.35 2.804 1.768 3.202 1.966.398.199.63.165.862-.099.233-.264.995-1.157 1.261-1.553.265-.397.53-.331.896-.199.365.132 2.322 1.09 2.72 1.29.398.198.664.297.763.462.1.165.1.959-.232 1.884-.332.926-1.923 1.77-2.687 1.884-.686.102-1.554.145-2.508-.157a22.976 22.976 0 0 1-2.27-.835c-3.993-1.716-6.601-5.718-6.8-5.982-.2-.265-1.626-2.149-1.626-4.099 0-1.95 1.028-2.908 1.393-3.305a1.466 1.466 0 0 1 1.062-.496z' />
				</g>
			</g>
		</SocialIcon>
	);
};

export { WhatsAppIcon };
