import React from "react";
import { Colors, TColors } from "../../theme";
import { SocialIcon } from "../SocialIcon";

interface LinkIconProps {
	color?: TColors;
}

const LinkIcon = (props: LinkIconProps) => {
	const { color } = props;
	return (
		<SocialIcon color={color || Colors.Text}>
			<g transform='translate(-762.5 -6)' fillRule='evenodd'>
				<g transform='translate(502.5 6)'>
					<path d='M287 0a5 5 0 0 1 5 5v22a5 5 0 0 1-5 5h-22a5 5 0 0 1-5-5V5a5 5 0 0 1 5-5h22zm-12.914 11.737a6.147 6.147 0 0 0-4.473 1.8l-2.95 2.949-.206.224a6.147 6.147 0 0 0 8.883 8.482l1.697-1.695.102-.116a1.231 1.231 0 0 0-1.843-1.625l-1.681 1.681-.176.16a3.683 3.683 0 0 1-4.975-.22 3.685 3.685 0 0 1-.045-5.166l2.935-2.934.18-.168a3.685 3.685 0 0 1 5.377.566 1.23 1.23 0 0 0 1.972-1.475 6.147 6.147 0 0 0-4.484-2.449zm11.052-5.014a6.147 6.147 0 0 0-8.617-.074l-1.704 1.693-.103.116a1.231 1.231 0 0 0 1.838 1.63l1.692-1.681.176-.16a3.684 3.684 0 0 1 4.977.217 3.685 3.685 0 0 1 .045 5.166l-2.935 2.935-.18.168a3.685 3.685 0 0 1-5.377-.566 1.23 1.23 0 0 0-1.971 1.474 6.147 6.147 0 0 0 9.269.664l2.95-2.95.206-.224a6.15 6.15 0 0 0-.266-8.408z' />
				</g>
			</g>
		</SocialIcon>
	);
};

export { LinkIcon };
