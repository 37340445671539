import React from "react";
import { PersonalityTypeIcon } from "../PersonalityTypeIcon";

const RAIcon = () => {
	return (
		<PersonalityTypeIcon>
			<svg
				width='120'
				height='120'
				xmlns='http://www.w3.org/2000/svg'
				xmlnsXlink='http://www.w3.org/1999/xlink'
			>
				<defs>
					<path id='a' d='M0 0h120v120H0z' />
					<path id='c' d='M0 0h68.538v101.333H0z' />
				</defs>
				<g fill='none' fillRule='evenodd'>
					<g>
						<mask id='b' fill='#fff'>
							<use xlinkHref='#a' />
						</mask>
						<path
							d='M60 0c33.131 0 60 26.869 60 60 0 33.132-26.869 60-60 60-33.132 0-60-26.868-60-60C0 26.869 26.868 0 60 0'
							fill='#DBD6BB'
							mask='url(#b)'
						/>
					</g>
					<g transform='translate(51.461 18.667)'>
						<mask id='d' fill='#fff'>
							<use xlinkHref='#c' />
						</mask>
						<path
							d='m0 0 19.525.632 45.558 20.603a59.874 59.874 0 0 1 3.455 20.098c0 33.131-26.868 60-60 60-.504 0-1.007-.007-1.507-.02L0 0z'
							fill='#B5AE87'
							mask='url(#d)'
						/>
					</g>
					<path
						d='M90.335 50.697c.914-1.48 2.345-3.147 1.866-5.31-.595-2.681-2.298-2.246-2.469-3.927-.197-1.908.98-2.397 1.677-3.823 1.122-2.275.38-4.316-1.579-5.834-1.494-1.159-3.418-1.892-2.761-4.21.617-2.184 1.62-2.088 1.085-4.684-.305-1.471-1.315-2.376-2.663-2.871-1.712-.63-3.111-1.256-3.226-3.19-.068-1.201-.425-2.358-1.707-3.508-3.16-2.2-4.547-.812-6.554-1.146-2.004-.331-2.29-1.702-3.117-2.679-2.031-2.378-6.216-2.441-8.178-1.077-1.012.702-1.629 1.187-2.944 1.266-1.114 0-2.026-.787-2.87-1.273-2.093-1.207-5.894-.975-7.923 1.403-.83.975-.602 2.029-2.608 2.36-2.007.334-3.394-1.055-6.551 1.146-1.285 1.15-1.642 2.307-1.71 3.508-.114 1.934-1.513 2.56-3.226 3.19-1.348.495-2.358 1.4-2.663 2.871-.534 2.596.468 2.5 1.086 4.685.659 2.317-1.268 3.05-2.762 4.209-1.958 1.518-2.7 3.56-1.578 5.834.702 1.426 1.875 1.915 1.676 3.823-.17 1.681-1.874 1.246-2.464 3.926-.484 2.164.947 3.832 1.862 5.311.837 1.355-.26 3.326.008 4.727.741 3.834 3.535 2.2 4.64 4.211.636 1.151.394 3.347 2.665 4.113 1.019.341 2.589-.171 3.64-.228 1.516-.09 2.397.292 3.636 1.06 3.207 2 10.36 2.223 14.528 2.322h2.069c4.165-.099 11.318-.321 14.525-2.322 1.24-.768 2.12-1.15 3.639-1.06 1.05.057 2.62.57 3.638.228 2.27-.766 2.03-2.962 2.663-4.113 1.107-2.01 3.9-.377 4.642-4.21.27-1.402-.829-3.373.008-4.728'
						fill='#231F20'
					/>
					<path
						d='m50.811 60.46 5.348.339h.003c4.375 0 8.613-.06 13.025-.34l.908 6.939c.472 3.603 1.543 6.06 5.318 6.556 9.536 1.256 15.455 2.841 19.648 9.02 3.216 4.74 5.917 11.962 5.123 17.717-.281 2.04-.821 3.762-1.585 5.237-10.434 8.778-23.9 14.071-38.599 14.071-14.702 0-28.167-5.293-38.602-14.073-.763-1.475-1.303-3.195-1.584-5.235-.794-5.755 1.904-12.976 5.123-17.718 4.193-6.178 10.111-7.763 19.646-9.019 3.778-.497 4.849-2.953 5.322-6.556l.906-6.939z'
						fill='#C48155'
					/>
					<path
						d='M71.975 72.396c.784.815 1.881 1.353 3.438 1.558 9.535 1.256 15.455 2.841 19.648 9.02 3.216 4.74 5.917 11.962 5.123 17.717-.281 2.04-.822 3.762-1.585 5.237C88.165 114.706 74.7 120 60 120c-14.7 0-28.166-5.294-38.601-14.074-.763-1.475-1.303-3.195-1.584-5.235-.794-5.755 1.904-12.976 5.123-17.718 4.193-6.178 10.111-7.763 19.646-9.019 1.557-.205 2.655-.743 3.44-1.557 3.262 2.872 7.433 4.59 11.978 4.59 4.546 0 8.714-1.718 11.974-4.591'
						fill='#DAD6C6'
					/>
					<path
						d='M45.07 51.932c1.624 5.826 4.503 10.08 8.236 13.249 5.902 5.012 7.812 4.979 13.694-.042 3.724-3.178 6.587-7.443 8.201-13.28.399.37 1.025.114 1.576-.311.696-.536 1.168-1.237 1.563-2.066 1.076-2.254 1.56-5.42.457-6.886a1.779 1.779 0 0 0-1.309-.725c.259-.955.524-1.948.793-3.022 2.62-10.463-6.352-19.397-18.284-20.904-11.333 1.13-20.25 10.498-18.025 20.904.417 1.941.787 2.17-.397 3.747-1.105 1.465-.62 4.632.457 6.886.394.83.866 1.53 1.563 2.066.507.391 1.078.639 1.475.384'
						fill='#E4A36F'
					/>
					<path
						d='M19.679 98.078c.132-8.253 3.772-15.053 5.071-17.088 1.61-2.512 4.062-3.911 6.349-4.317 3.964-.699 6.744-2.087 10.6-2.678.092 12.329 3.686 30.905 5.619 44.657-10.655-2.294-20.257-7.417-27.984-14.545.027-2.03.124-4.042.345-6.029'
						fill='#877E57'
					/>
					<path
						d='M79.793 36.097c6.491-28.462-45.054-28.984-39.353.102.384 1.957.199 3.549.473 5.19 1.113.308 2.975.698 3.535.675-1.638-1.343-2.958-3.864-1.81-5.83.601-1.043 1.242-1.213 1.53-2.492.248-1.099.167-2.272.143-3.406.115 2.343 2.819 4.212 3.904 4.547-.98-1.224-.65-2.73.454-4.598.774-1.316.806-.702 1.056-2.153 2.608.575 4.934 1.145 1.958 4.484 4.085-.771 6.017-2.352 5.023-5.367 3.488.547.793 4.757 4.456 5.185-1.636-2.754 2.24-2.537 3.052-5.364 3.477 1.778 2.693 3.369.886 5.658 4.01.22 5.799-.757 6.272-4.33 1.71.559 4.067 1.5.795 5.567 1.298.672 3.176 1.811 4.16 1.139 2.937 1.888 1.832 6.024-.408 7.158 1.512.283 2.734-.21 3.688-1.092.151-1.592-.24-3.214.186-5.073'
						fill='#231F20'
					/>
					<path
						d='m40.256 74.246 2.885-.67c1.177-.535 5.235-3.413 5.752-4.31L60 76.986l-12.68 9.015-7.064-11.755zM79.742 74.246l-2.884-.67c-1.178-.535-5.235-3.413-5.748-4.31l-11.109 7.72 12.677 9.015 7.064-11.755z'
						fill='#FBF9F4'
					/>
					<path
						d='M100.32 98.078c-.131-8.253-3.772-15.053-5.07-17.088-1.61-2.512-4.063-3.911-6.35-4.317-3.964-.699-6.744-2.087-10.598-2.678-.093 12.329-3.686 30.905-5.62 44.657 10.654-2.293 20.256-7.417 27.984-14.545-.027-2.03-.125-4.042-.346-6.029'
						fill='#877E57'
					/>
					<path
						d='m33.826 114.001.049-.475c1.135-11.161.935-11.428 1.37-.009l.046 1.174c-.493-.224-.98-.454-1.465-.69M84.761 114.666l.043-1.149c.423-11.42.23-11.152 1.336.01l.047.468c-.473.23-.947.454-1.426.67'
						fill='#6D6034'
					/>
					<path
						d='M63.466 44.857c.291.842.732 1.635 1.474 2.33.626.582 1.26.902 2.112 1.147 1.768.51 3.565.371 5.382-.405.73-.315 1.17-.68 1.554-1.343.297-.517.496-1.12.622-1.785.223-1.198.445-2.946-1.088-3.514-.327-.122-.723-.186-1.177-.196a37.27 37.27 0 0 0-3.065.062c-1.081.07-2.596.106-3.837.444-1.858.51-2.529 1.664-1.977 3.26m-6.562 0c-.29.842-.73 1.635-1.474 2.33-.626.582-1.26.902-2.112 1.147-1.771.51-3.561.371-5.38-.405-.731-.315-1.173-.68-1.556-1.343-.297-.517-.493-1.12-.622-1.785-.223-1.198-.441-2.946 1.089-3.514.327-.122.72-.186 1.18-.196a37.18 37.18 0 0 1 3.06.062c1.086.07 2.597.106 3.838.444 1.858.51 2.529 1.664 1.977 3.26m-2.203-4.317a47.153 47.153 0 0 0-10.422-.39c-.637.04-1.146.031-1.29.77-.082.434.064.677.181.845.132.19.37.316.687.626.754.741.735 2.627 1.136 3.632.301.738.663 1.32 1.11 1.771.98.99 2.354 1.384 4.267 1.559 1.77.166 3.034.023 4.097-.475 1.067-.502 1.93-1.365 2.909-2.646.699-.918.97-2.36 1.944-2.797.321-.145 1.413-.145 1.73 0 .976.437 1.245 1.879 1.944 2.797.98 1.28 1.842 2.144 2.909 2.646 1.063.498 2.326.64 4.097.475 1.913-.175 3.286-.568 4.266-1.559.448-.451.81-1.033 1.11-1.771.402-1.005.387-2.89 1.138-3.632.315-.31.554-.437.686-.626a1.01 1.01 0 0 0 .181-.844c-.144-.74-.65-.73-1.287-.772a47.127 47.127 0 0 0-10.421.391c-6.676 1.009-4.3 1.009-10.972 0'
						fill='#F6F4EE'
					/>
					<path
						d='M43.035 88.523a.75.75 0 0 1 .843-.512l.099.024 30.756 9.759c.674.213.693 1.133.081 1.4l-.107.037-26.083 7.237 23.01 9.52a.75.75 0 0 1 .438.883l-.033.096a.75.75 0 0 1-.883.44l-.096-.033-25.024-10.352c-.622-.257-.605-1.121-.022-1.377l.108-.039 25.752-7.146-28.35-8.995a.75.75 0 0 1-.513-.843l.024-.099z'
						fill='#EBE9E5'
						fillRule='nonzero'
					/>
				</g>
			</svg>
		</PersonalityTypeIcon>
	);
};

export { RAIcon };
