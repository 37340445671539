import { PersonalityType } from "./PersonalityType";

function _getPointBreakpoints() {
	return [8, 11, 14, 16, 18, 20, 21, 22, 23, 25] as const;
}

function getParsedPersonalityTypePoints(
	personalityType: PersonalityType,
	personalityTypePoints: number
) {
	const breakpoints = _getPointBreakpoints();
	const [maximumPoints] = breakpoints.slice(-1);

	if (personalityTypePoints <= breakpoints[0]) {
		return 10;
	} else if (personalityTypePoints <= breakpoints[1]) {
		return 20;
	} else if (personalityTypePoints <= breakpoints[2]) {
		return 30;
	} else if (personalityTypePoints <= breakpoints[3]) {
		return 40;
	} else if (personalityTypePoints <= breakpoints[4]) {
		return 50;
	} else if (personalityTypePoints <= breakpoints[5]) {
		return 60;
	} else if (personalityTypePoints <= breakpoints[6]) {
		return 70;
	} else if (personalityTypePoints <= breakpoints[7]) {
		return 80;
	} else if (personalityTypePoints <= breakpoints[8]) {
		return 90;
	} else if (personalityTypePoints <= breakpoints[9]) {
		return 100;
	}

	if (personalityTypePoints > maximumPoints) {
		throw new Error(
			`Personality type points (${personalityTypePoints}) cannot exceed ${maximumPoints}.`
		);
	}
}

export { getParsedPersonalityTypePoints };
