import React from "react";
import { PersonalityTypeIcon } from "../PersonalityTypeIcon";

const RSIcon = () => {
	return (
		<PersonalityTypeIcon>
			<svg
				width='120'
				height='120'
				xmlns='http://www.w3.org/2000/svg'
				xmlnsXlink='http://www.w3.org/1999/xlink'
			>
				<defs>
					<path id='a' d='M0 0h120v120H0z' />
				</defs>
				<g fill='none' fillRule='evenodd'>
					<g>
						<mask id='b' fill='#fff'>
							<use xlinkHref='#a' />
						</mask>
						<path
							d='M60 0c33.132 0 60 26.868 60 60 0 33.132-26.868 60-60 60C26.87 120 0 93.132 0 60 0 26.867 26.869 0 60 0'
							fill='#CDDBEC'
							mask='url(#b)'
						/>
					</g>
					<path
						d='m51.461 18.667 19.526.631 45.558 20.603A59.881 59.881 0 0 1 120 60C120 93.131 93.132 120 60 120a62.95 62.95 0 0 1-1.508-.02l-7.03-101.313z'
						fill='#A9C1D6'
					/>
					<path
						d='M15.88 84.691c-1.92 3.786-3.015 8.536-2.524 13.041C24.357 111.313 41.166 120 60 120c18.838 0 35.648-8.69 46.648-22.274.49-4.503-.605-9.25-2.527-13.035-7.605-14.99-27.172-12.514-29.761-16.364-2.011-2.988-.816-12.176-.948-14.543-9.37.896-17.36.7-26.726-.196-.131 2.366.968 11.751-1.042 14.74-2.59 3.85-22.157 1.373-29.764 16.363'
						fill='#CF8D60'
					/>
					<path
						d='m15.436 84.691-.184.371c-1.528 3.146-2.533 6.965-3.197 11.008C23.005 110.6 40.406 120 60 120s36.996-9.4 47.946-23.932c-.663-4.042-1.67-7.86-3.196-11.006l-.182-.37c-2.632-5.183-6.747-8.277-11.108-10.268-4.218-2.242-11.101-4.733-15.227-6.243-2.785 6.945-9.52 12.682-17.596 12.682-7.244 0-17.95-6.884-20.07-12.19-4.121 1.509-9.8 3.504-14.013 5.745-4.243 1.934-8.253 4.911-10.891 9.84a24.54 24.54 0 0 0-.227.433'
						fill='#F5F5F6'
					/>
					<path
						d='M66.182 119.685 63.656 88.74l7.718-7.877-5.195-2.518c-1.75.412-3.61.633-5.542.633-2.24 0-4.385-.297-6.374-.846l-5.637 2.731 7.755 7.877-2.558 30.945a60.645 60.645 0 0 0 12.36 0'
						fill='#00124F'
					/>
					<path
						fill='#FFF'
						d='m45.825 67.053 14.196 12.011L49.5 89.307l-9.252-20.822zM74.24 67.053 60.043 79.064l10.522 10.243 9.252-20.822z'
					/>
					<path
						d='M79.671 40.872c-.57-.705-1.394-.885-2.237-.766.02-.82.036-1.64.036-2.446 0-4.679-.015-10.421-1.61-13.697-1.163-2.394-2.931-3.96-5.025-5.122-8.015-4.441-13.651-4.441-21.666 0-2.094 1.162-3.86 2.728-5.024 5.122-1.597 3.276-1.612 9.018-1.612 13.697 0 .806.015 1.625.037 2.446-.84-.12-1.667.061-2.237.766-1.322 1.63-.74 5.159.548 7.669.473.919 1.039 1.704 1.873 2.298.34.245.701.435 1.036.508.564 2.652 1.348 5.161 2.412 7.395 1.477 3.102 3.497 5.678 6.219 7.385 4.27 2.679 10.895 2.679 15.165 0 2.721-1.707 4.738-4.283 6.216-7.385 1.066-2.234 1.848-4.743 2.412-7.395.336-.073.698-.263 1.039-.508.833-.594 1.4-1.38 1.873-2.298 1.287-2.51 1.866-6.039.545-7.67'
						fill='#E9AE7A'
					/>
					<path
						d='M57.177 14.835c-2.911.193-5.7.739-7.671 2.03a16.354 16.354 0 0 0-4.582 4.508c-1.195 1.742-1.833 3.282-2.235 4.885-.744 2.958-1.173 6.421-.895 9.47.08.844.138 2.025.267 2.827.055.339.153.67.266 1 .5 1.444 3.108 7.624 3.681 7.919.175.092-.193-5.038-.205-8.39-.012-3.178.236-6.31 1.628-9.373.705-1.54.957-2.064 2.731-2.385 5.336-.97 15.042-.97 20.375 0 1.775.321 2.03.845 2.731 2.385 1.395 3.062 1.64 6.195 1.63 9.374-.011 3.351-.38 8.48-.201 8.389.567-.295 3.178-6.475 3.678-7.92.11-.328.211-.66.263-1 .129-.8.19-1.982.267-2.825.282-3.05-.15-6.513-.892-9.47-.404-1.604-1.042-3.144-2.235-4.886a16.404 16.404 0 0 0-4.582-4.507c-1.973-1.292-4.763-1.838-7.671-2.031a48.394 48.394 0 0 0-6.348 0'
						fill='#504E4B'
					/>
					<path
						d='m27.722 106.07-.414 4.242c.206.134.412.267.62.399l-.206-4.642zM92.28 106.07l.414 4.24c-.206.135-.411.268-.62.4l.206-4.64z'
						fill='#C5C7C9'
					/>
					<path
						d='M36 92.484a8.549 8.549 0 0 1 9.129 7.93c.332 4.706-3.217 8.798-7.926 9.13a8.553 8.553 0 0 1-9.13-7.928c-.331-4.71 3.216-8.8 7.927-9.132'
						fill='#58595B'
					/>
					<path
						d='M36.166 94.837a6.193 6.193 0 0 1 6.612 5.741 6.195 6.195 0 0 1-5.74 6.618 6.195 6.195 0 0 1-.872-12.36'
						fill='#9C9EA1'
					/>
					<path
						d='M32.13 71.857a28.565 28.565 0 0 0-.245 2.724c-.188 4.658.5 10.677 2.21 18.773l2.723-.574c-1.662-7.862-2.33-13.661-2.15-18.088.067-1.496.253-2.829.476-4.02-1.469-.41-2.442-.03-3.013 1.185M82.433 69.73c1.633 5.392 1.813 12.19 1.378 17.603h3.146c.377-4.842.103-11.566-1.009-16.252-1.082-1.265-2.285-1.627-3.515-1.351'
						fill='#58595B'
					/>
					<path
						d='M80.408 87.61c-3.047 3.245-5.41 8.137-5.442 19.538-.002 1.87 3.124 1.796 3.127.064.015-10.463 1.993-14.57 4.546-17.288 1.636-1.743 3.563-1.745 5.202 0 2.559 2.725 4.54 6.855 4.546 17.366 0 1.612 3.127 1.66 3.123-.001-.02-11.558-2.38-16.421-5.438-19.68-2.861-3.052-6.804-3.052-9.664 0'
						fill='#58595B'
					/>
					<path
						d='M75.79 107.827a.783.783 0 1 1 1.564-.11l.003.037c.037.503.089.997.153 1.47.065.477.147.951.242 1.415.465 2.22 1.26 4.049 2.412 4.591a.782.782 0 0 1 .285 1.191c-.264.096-.529.188-.794.28a.757.757 0 0 1-.152-.053c-1.663-.783-2.727-3.028-3.283-5.692-.101-.489-.19-1-.26-1.525a25.503 25.503 0 0 1-.167-1.568l-.002-.036z'
						fill='#6D6E70'
					/>
					<path
						d='M78.452 114.715a1.288 1.288 0 0 1 1.814-.11l.842.745c.209.184.342.421.399.674-.825.316-1.658.617-2.5.899l-.444-.393a1.29 1.29 0 0 1-.11-1.815M94.729 107.828a.783.783 0 1 0-1.562-.11l-.002.036c-.04.503-.09.997-.154 1.47-.044.33-.097.657-.157.981.595-.39 1.184-.787 1.762-1.197.046-.385.083-.768.112-1.144v-.036z'
						fill='#6D6E70'
					/>
					<path
						d='M42.593 26.648h35.512c.717 1.204.876 2.63.717 4.13H41.877c-.294-1.344.007-2.756.716-4.13'
						fill='#A6A8AB'
					/>
					<path
						d='M60.002 19.362c5.05 0 9.141 4.282 9.141 9.561 0 5.276-4.092 9.56-9.141 9.56-5.05 0-9.14-4.284-9.14-9.56 0-5.28 4.09-9.56 9.14-9.56'
						fill='#D7D8DA'
					/>
					<path
						d='M60.002 26.372c1.348 0 2.438 1.143 2.438 2.551 0 1.404-1.09 2.549-2.438 2.549-1.347 0-2.439-1.145-2.439-2.549 0-1.408 1.092-2.551 2.439-2.551'
						fill='#58595B'
					/>
				</g>
			</svg>
		</PersonalityTypeIcon>
	);
};

export { RSIcon };
