import React from "react";
import { PersonalityTypeIcon } from "../PersonalityTypeIcon";

const ASIcon = () => {
	return (
		<PersonalityTypeIcon>
			<svg
				width='120'
				height='120'
				xmlns='http://www.w3.org/2000/svg'
				xmlnsXlink='http://www.w3.org/1999/xlink'
			>
				<defs>
					<path id='a' d='M0 0h120v120H0z' />
					<path id='c' d='M0 0h68.538v101.332H0z' />
					<path id='e' d='M0 0h94.015v66.412H0z' />
					<path id='g' d='M0 0h95.89v51.818H0z' />
				</defs>
				<g fill='none' fillRule='evenodd'>
					<g>
						<mask id='b' fill='#fff'>
							<use xlinkHref='#a' />
						</mask>
						<path
							d='M60 0c33.131 0 60 26.869 60 60s-26.869 60-60 60S0 93.13 0 60 26.869 0 60 0'
							fill='#A6C2B7'
							mask='url(#b)'
						/>
					</g>
					<g transform='translate(51.462 18.667)'>
						<mask id='d' fill='#fff'>
							<use xlinkHref='#c' />
						</mask>
						<path
							d='m0 0 19.525.632 45.558 20.603a59.9 59.9 0 0 1 3.455 20.098c0 33.131-26.869 60-60 60-.504 0-1.007-.007-1.509-.02L0 0z'
							fill='#90AFA0'
							mask='url(#d)'
						/>
					</g>
					<g transform='translate(12.994 53.588)'>
						<mask id='f' fill='#fff'>
							<use xlinkHref='#e' />
						</mask>
						<path
							d='M2.442 31.103C.56 34.816-.392 39.456.152 43.883c10.997 13.73 27.899 22.529 46.854 22.529 18.958 0 35.86-8.8 46.857-22.534.543-4.425-.406-9.063-2.29-12.775-7.606-14.99-27.619-12.513-30.207-16.362-2.012-2.988-.815-12.177-.949-14.544C51.05 1.092 43.058.895 33.692 0c-.132 2.367.97 11.753-1.042 14.74-2.59 3.85-22.6 1.373-30.208 16.363'
							fill='#CB9568'
							mask='url(#f)'
						/>
					</g>
					<g transform='translate(12.055 68.181)'>
						<mask id='h' fill='#fff'>
							<use xlinkHref='#g' />
						</mask>
						<path
							d='M3.38 16.51a20.78 20.78 0 0 0-.184.371C1.67 20.027.663 23.844 0 27.888c10.95 14.531 28.352 23.93 47.945 23.93s36.994-9.399 47.945-23.931c-.663-4.043-1.668-7.86-3.196-11.006-.06-.125-.121-.248-.183-.371-2.631-5.182-6.745-8.277-11.108-10.267C77.187 4 70.303 1.511 66.176 0c-2.783 6.944-9.52 12.681-17.594 12.681-7.247 0-17.95-6.884-20.07-12.19-4.121 1.51-9.801 3.505-14.013 5.745-4.244 1.934-8.253 4.911-10.892 9.84a37.47 37.47 0 0 0-.226.434'
							fill='#F5F5F6'
							mask='url(#h)'
						/>
					</g>
					<path
						d='M66.622 119.635 64.098 88.74l4.605-7.877-2.525-2.52c-1.75.414-3.61.635-5.541.635-2.24 0-4.386-.297-6.375-.845l-2.97 2.73 4.644 7.877-2.553 30.895c2.173.239 4.38.364 6.617.364a60.66 60.66 0 0 0 6.622-.364'
						fill='#00124F'
					/>
					<path
						d='M57.248 78.748a23.716 23.716 0 0 1-2.68-.532l-2.627 3.745 1.394 2.366 3.913-5.579zM65.286 103.292l-.318-3.905-11.237 16.018-.348 4.23c.135.014.27.032.406.046l11.497-16.39zM65.805 109.64l-.317-3.896-9.888 14.094c.977.071 1.961.119 2.951.144l7.254-10.343zM66.326 115.996l-.318-3.905-5.545 7.903a60.27 60.27 0 0 0 3.13-.104l2.733-3.894zM66.622 119.635l-.097-1.19-.906 1.29c.336-.031.67-.064 1.003-.1M62.288 78.927a26.521 26.521 0 0 1-3.063.012l-4.926 7.022 1.392 2.367 6.597-9.401zM67.057 79.22l-.879-.877c-.591.142-1.196.26-1.811.354L55.75 90.982l-.407 4.936 11.714-16.697zM64.229 90.343l-.131-1.602 4.605-7.878-.517-.517-13.1 18.675-.409 4.94zM64.748 96.696l-.318-3.904-10.008 14.268-.408 4.94z'
						fill='#F1B70F'
					/>
					<path
						fill='#FFF'
						d='m45.824 67.053 14.196 12.01-10.52 5.798-9.253-16.375zM74.24 67.053l-14.198 12.01 10.522 5.798 9.251-16.375z'
					/>
					<path
						d='M79.671 40.873c-.57-.706-1.395-.886-2.238-.768.02-.819.036-1.638.036-2.445 0-4.679-.015-10.422-1.61-13.696-1.164-2.394-2.932-3.961-5.025-5.124-8.015-4.44-13.652-4.44-21.665 0-2.094 1.163-3.86 2.73-5.024 5.124-1.598 3.274-1.612 9.017-1.612 13.696 0 .807.014 1.626.037 2.445-.84-.118-1.669.062-2.238.768-1.32 1.63-.74 5.157.548 7.668.472.92 1.04 1.703 1.874 2.298.34.246.701.435 1.034.507.566 2.653 1.35 5.163 2.414 7.397 1.477 3.102 3.496 5.675 6.218 7.385 4.27 2.678 10.897 2.678 15.166 0 2.721-1.71 4.737-4.283 6.215-7.385 1.067-2.234 1.848-4.744 2.412-7.397.337-.072.699-.261 1.039-.507.833-.595 1.4-1.379 1.872-2.298 1.286-2.511 1.868-6.039.547-7.668'
						fill='#F0C092'
					/>
					<path
						d='m27.975 106.07-.668 4.241c.332.135.665.268 1 .399l-.332-4.64zM92.404 106.07l.67 4.24c-.333.134-.665.269-1 .4l.33-4.64z'
						fill='#C5C7C9'
					/>
					<path
						d='M88.853 43.907c-4.674.609-4.307-4.833-4.43-6.682-.431-6.486-.41-12.662-5.31-18.309-3.303-3.801-8.473-6.76-13.475-7.347-5.733-.67-16.4-.469-23.162 5.653-3.885 3.515-7.787 7.755-8.028 15.84.224 4.865.702 6.118 1.013 8.979.157 3.094-.24 6.735-2.77 5.634.73.724 1.853 1.548 3.256.733.095.55-.104 2.36-.054 2.907.605 6.489 3.809 6.871 2.486 11.13 1.873-.222 2.015-2.867 2.89-4.903 1.115 4.929 2.713 7.616-1.762 10.8 13.927-2.704-.538-23.54 6.654-33.999-.022.435.668 1.962 2.593 2.376-.648-2.16 1.011-4.897 2.524-5.693-.071 1.299-.938 3.1 1.728 5.622-.232-2.289 1.08-4.9 3.963-7.494-.464.812-.36 6.269 2.163 7.351-1.192-5.461 5.986-4.637 6.486-10.594 5.063 2.707 10.99 9.371 10.095 22.876-.895 5.004-6.837 18.78 5.731 20.574-4.845-2.248-5.153-5.389-2.874-9.167.124.256-.522 3.577 1.646 5.173.065.214-1.2-1.217.075-5.1.615-2.408 1.857-5.802 2.98-8.077.312.85.075 1.61 0 3.7 2.06-4.33 1.555-8.492.216-12.59 1.32 1.122 3.92 2.024 5.366.607'
						fill='#926335'
					/>
					<path
						d='M75.036 43.792c-.134.747-.34 1.423-.649 2-.4.748-.859 1.155-1.62 1.507-1.893.872-3.766 1.024-5.61.456-.887-.274-1.551-.631-2.198-1.288-.776-.78-1.236-1.668-1.54-2.613-.573-1.79.124-3.084 2.063-3.653 1.294-.382 2.87-.42 3.997-.5a36.005 36.005 0 0 1 3.192-.066c.475.01.89.08 1.23.217 1.597.639 1.37 2.596 1.135 3.94m-18.457.062c-.301.945-.76 1.833-1.535 2.613-.65.657-1.316 1.014-2.203 1.288-1.843.568-3.713.416-5.608-.456-.763-.352-1.22-.76-1.623-1.508-.306-.576-.512-1.252-.644-1.999-.238-1.344-.466-3.301 1.134-3.94.343-.136.753-.207 1.231-.217a35.936 35.936 0 0 1 3.19.067c1.13.08 2.702.117 3.996.499 1.94.57 2.64 1.863 2.062 3.653m21.344-4.41c-.15-.83-.679-.818-1.342-.865a45.607 45.607 0 0 0-10.86.438c-6.959 1.13-4.482 1.13-11.44 0a45.61 45.61 0 0 0-9.918-.502c-.313.02-.625.039-.94.064-.665.047-1.194.035-1.347.865-.085.484.067.757.191.943.136.214.387.357.713.706.785.831.766 2.943 1.186 4.07.31.828.694 1.478 1.155 1.983 1.022 1.113 2.453 1.552 4.45 1.747 1.846.187 3.16.03 4.27-.533 1.11-.56 2.01-1.53 3.03-2.965.729-1.026 1.01-2.645 2.027-3.135.336-.161 1.471-.161 1.805 0 1.016.49 1.295 2.109 2.025 3.135 1.019 1.435 1.923 2.404 3.03 2.965 1.113.562 2.427.72 4.272.533 1.995-.195 3.423-.634 4.447-1.747.466-.505.843-1.155 1.155-1.983.423-1.127.405-3.239 1.19-4.07.328-.349.576-.492.713-.706.124-.186.276-.459.188-.943'
						fill='#2D343E'
					/>
				</g>
			</svg>
		</PersonalityTypeIcon>
	);
};

export { ASIcon };
