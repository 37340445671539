import { Colors } from "../../theme";

export const options = {
	scales: {
		r: {
			pointLabels: {
				color: Colors.Text,
				font: {
					size: 12,
					family: "Barlow, Arial, Helvetica, sans-serif",
					weight: "500",
				},
			},
			ticks: {
				display: false,
			},
		},
	},
	scale: {
		min: 0,
		max: 100,
		ticks: {
			beginAtZero: true,
			stepSize: 25,
		},
	},
	plugins: {
		legend: {
			display: false,
		},
		tooltip: {
			enabled: false,
		},
	},
} as const;
