import React from "react";
import { Radar } from "react-chartjs-2";
import { QuizResult } from "../../../models/QuizResult";
import { options } from "./options";
import { getChartData } from "./getChartData";

interface RadarChartProps {
	quizResult: QuizResult;
}

const RadarChart = (props: RadarChartProps) => {
	const { quizResult } = props;
	const data = getChartData(quizResult);

	return <Radar data={data} options={options} />;
};

export { RadarChart };
