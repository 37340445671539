import { Colors } from "../../theme";
import { QuizResult } from "../../../models/QuizResult";
import { PersonalityTypes } from "../../../models/PersonalityType";
import { getParsedPersonalityTypePoints } from "../../../models/getParsedPersonalityTypePoints";

export function getChartData(quizResult: QuizResult) {
	const labels = [
		PersonalityTypes.REALISTYCZNY,
		PersonalityTypes.BADAWCZY,
		PersonalityTypes.ARTYSTYCZNY,
		PersonalityTypes.SPOLECZNY,
		PersonalityTypes.PRZEDSIEBIORCZY,
		PersonalityTypes.KONWENCJONALNY,
	];

	const data = [
		getParsedPersonalityTypePoints(
			PersonalityTypes.REALISTYCZNY,
			quizResult[PersonalityTypes.REALISTYCZNY]
		),
		getParsedPersonalityTypePoints(
			PersonalityTypes.BADAWCZY,
			quizResult[PersonalityTypes.BADAWCZY]
		),
		getParsedPersonalityTypePoints(
			PersonalityTypes.ARTYSTYCZNY,
			quizResult[PersonalityTypes.ARTYSTYCZNY]
		),
		getParsedPersonalityTypePoints(
			PersonalityTypes.SPOLECZNY,
			quizResult[PersonalityTypes.SPOLECZNY]
		),
		getParsedPersonalityTypePoints(
			PersonalityTypes.PRZEDSIEBIORCZY,
			quizResult[PersonalityTypes.PRZEDSIEBIORCZY]
		),
		getParsedPersonalityTypePoints(
			PersonalityTypes.KONWENCJONALNY,
			quizResult[PersonalityTypes.KONWENCJONALNY]
		),
	];

	return {
		labels,
		datasets: [
			{
				data: data,
				backgroundColor: Colors.TransparentGreen,
				borderColor: Colors.Green,
				borderWidth: 3,
				pointRadius: 0,
			},
		],
	};
}
