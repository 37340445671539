import React from "react";
import { Colors, TColors } from "../../theme";
import { SocialIcon } from "../SocialIcon";

interface MailIconProps {
	color?: TColors;
}

const MailIcon = (props: MailIconProps) => {
	const { color } = props;
	return (
		<SocialIcon color={color || Colors.Text}>
			<g transform='translate(-710.5 -6)' fillRule='evenodd'>
				<g transform='translate(502.5 6)'>
					<path d='M213 0h22a5 5 0 0 1 5 5v22a5 5 0 0 1-5 5h-22a5 5 0 0 1-5-5V5a5 5 0 0 1 5-5zm21.667 22.417v-9.084c-.43.486-.875.868-1.334 1.146-.472.306-1.392.896-2.76 1.771a145.97 145.97 0 0 0-3.156 2.063c-1.361.958-2.5 1.437-3.417 1.437-.917 0-2.056-.48-3.417-1.438a89.558 89.558 0 0 0-2.948-1.927 116.9 116.9 0 0 1-2.968-1.927 6.557 6.557 0 0 1-.688-.562 15.865 15.865 0 0 0-.646-.563v9.084c0 .555.195 1.027.584 1.416.389.39.86.584 1.416.584h17.334c.555 0 1.027-.195 1.416-.584.39-.389.584-.86.584-1.416zm0-12.926c0-.57-.191-1.055-.573-1.458a1.886 1.886 0 0 0-1.427-.604h-17.334c-.555 0-1.027.194-1.416.583a1.929 1.929 0 0 0-.584 1.417c0 .513.212 1.045.636 1.593.423.549.892.997 1.406 1.344.653.445 1.608 1.063 2.865 1.854 1.257.792 2.156 1.368 2.697 1.73.042.027.16.107.355.239.194.132.34.23.437.292.097.062.243.152.438.27a5.62 5.62 0 0 0 .937.47c.167.069.323.12.469.155.146.035.288.053.427.053s.281-.018.427-.053c.146-.034.302-.086.469-.156a5.62 5.62 0 0 0 .938-.469c.194-.118.34-.208.437-.27.097-.063.243-.16.437-.292l.355-.24 5.562-3.625c.486-.319.948-.753 1.385-1.302.438-.548.657-1.059.657-1.53z' />
				</g>
			</g>
		</SocialIcon>
	);
};

export { MailIcon };
