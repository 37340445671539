import { QuizResult } from "./QuizResult";
import { PersonalityType } from "./PersonalityType";
import { getParsedPersonalityTypePoints } from "./getParsedPersonalityTypePoints";

function getDominantPersonalityTypes(quizResult: QuizResult) {
	const entries = Object.entries(quizResult);

	// In order to keep the order of identical results unchanged, we need to sort them alphabetically
	const sortedEntries = [...entries].sort((a, b) => a[0].localeCompare(b[0]));

	return sortedEntries
		.map((entry) => {
			const parsedPoints = getParsedPersonalityTypePoints(
				entry[0] as PersonalityType,
				entry[1]
			);
			return [entry[0], parsedPoints];
		})
		.sort((prev, next) => Number(prev[1]) - Number(next[1]))
		.slice(-2)
		.map((entry) => entry[0])
		.reverse() as PersonalityType[];
}

export { getDominantPersonalityTypes };
